import './style.scss'

export default !function () {
  const blocks = document.querySelectorAll('.universal-certificate-v2')

  if (!blocks.length) {
    return
  }

  blocks.forEach((block) => {
    const blockName = block.closest('[data-block-name]')?.dataset.blockName || 'Блок без названия'
    const actionButton = block.querySelector('.universal-certificate-v2__button')
    const descriptionLinks = block.querySelectorAll('.universal-certificate-v2__desc a')

    actionButton?.addEventListener('click', () => {
      window.$analytics?.push('universal-certificate-button-click', {
        blockName
      })
    })

    descriptionLinks.forEach((link) => {
      link.addEventListener('click', () => {
        window.$analytics?.push('all-block-link-in-description-click', {
          blockName
        })
      })
    })
  })
}()
