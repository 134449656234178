import './style.scss'
import { Slider } from '../../../js/components/Slider/Slider'

export default !function () {
  const recommendations = document.querySelector('.retail-rocket')

  if (!recommendations) {
    return
  }

  if (!window.$retailRocket) {
    recommendations.remove()
    return
  }

  const wrapper = recommendations.querySelector('.retail-rocket__wrapper')
  const blockName = recommendations.dataset.rrBlockName

  const list = recommendations.querySelector('.retail-rocket__list')
  let observer = null

  const initObserver = () => {
    observer = new IntersectionObserver(observerCallback)
    observer.observe(wrapper)
  }

  const destroyObserver = () => {
    observer?.disconnect()
    observer = null
  }

  const observerCallback = (entries) => {
    entries[0].isIntersecting && sendPush()
  }

  const sendPush = () => {
    window.$retailRocket?.sendEvent('recomBlockViewed', window.$retailRocket.blocks.landgen[blockName].id)
    destroyObserver()
  }

  const initRrCardClick = () => {
    list.addEventListener('click', (evt) => {
      const item = evt.target.closest('.retail-rocket__item')
      if (item) {
        const offerId = item.dataset.offerId
        window.$retailRocket?.sendEvent('recomTap', window.$retailRocket.blocks.landgen[blockName].id, offerId)
      }
    })
  }

  // TODO picture на js компонент из дс
  const getItemTemplate = (data) => {
    return (
      `<li class="retail-rocket__item" data-offer-id="${data.offer_id}">
        <article data-theme="${data.dark_mode ? 'dark' : 'light'}" class="retail-rocket__card ui-product-card popular-slider__card" style="--ui-product-card-background:${data.background};">
          <div class="ui-product-card-main ui-product-card__main">
            <div class="ui-product-card-main__labels">
              <span class="ui-product-card-main__label f f--12">
                ${data.label}
              </span>
            </div>

            <a href="${data.href}" target="_blank" class="ui-product-card-main__wrap">
              <h3 class="ui-product-card-main__title t t--2">${data.title}</h3>
            </a>

            <picture class="ui-picture ui-product-card-main__pic-wrapper">
              <source srcset="${data.images.thumbnail.webp}" type="image/webp">
              <img src="${data.images.thumbnail.image}" width="64" height="64" loading="lazy" decoding="async" alt="" class="ui-picture__img ui-picture__img--cover">
            </picture>
          </div>

          <span class="retail-rocket__duration f f--12">
            <b class="f f--12 f--m">${data.duration.count}</b> ${data.duration.label}
          </span>
        </article>
      </li>`
    )
  }

  const initRrBlock = async () => {
    /*eslint-disable */
    const params = blockName === 'mainSlider' && typeof rr !== 'undefined'
      ? { landingId: rr.id }
      : null

    const rrItems = await window.$retailRocket.blocks.landgen[blockName].get(params).catch(() => {})

    /*eslint-enable */

    if (!rrItems || rrItems.length === 0) {
      return recommendations.remove()
    }

    const elements = rrItems.reduce((items, item) => {
      return `${items}${getItemTemplate(item)}`
    }, '');

    list.insertAdjacentHTML('afterbegin', elements)

    const items = list.querySelectorAll('.retail-rocket__item')
    const controls = recommendations.querySelector('.retail-rocket__controls')

    const options = {
      wrapper,
      settings: {
        slideClass: 'retail-rocket__item',
        wrapperClass: 'retail-rocket__list',
        loop: true,
        spaceBetween: 32,
        slidesPerView: 2,
        centeredSlides: false,

        pagination: {
          el: '.retail-rocket__count',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.button-arrow--next',
          prevEl: '.button-arrow--prev',
          disabledClass: 'button-arrow--disabled'
        },

        breakpoints: {
          1280: {
            slidesPerView: 3
          },
        }
      },
      modules: ['pagination', 'navigation'],
      initCondition: {
        sm: false,
        md: false,
        lg: items.length > 2,
        xl: items.length > 3
      },
      controls,
      createCallback: () => {
        recommendations.classList.add('retail-rocket--init')
        list.classList.remove('retail-rocket__list--no-slider')
      },
      destroyCallback:() => {
        recommendations.classList.remove('retail-rocket--init')
        list.classList.add('retail-rocket__list--no-slider')
      }
    }

    new Slider(options).init()
    initObserver()
    initRrCardClick()
  }

  initRrBlock()
}()
