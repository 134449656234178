import './style.scss'
import { SpeakerModel } from './Speaker/SpeakerModel'
import { Speaker } from './Speaker/Speaker'
import { Slider } from '../../../js/components/Slider/Slider'
import { Media } from '../../../js/utils/const'

export default !function () {
  const wrapper = document.querySelector('.speakers-v3__wrapper')

  if (!wrapper) {
    return
  }

  let slider = null
  const xl = window.matchMedia(`(min-width: ${Media.XL}px)`)
  const list = wrapper.querySelector('.speakers-v3__list')
  const items = list.querySelectorAll('.speakers-v3__item')
  const controls = wrapper.querySelector('.speakers-v3__controls')

  const options = {
    wrapper,
    settings: {
      loop: false,
      speed: 250,
      spaceBetween: 8,
      slideClass: 'speakers-v3__item',
      wrapperClass: 'speakers-v3__list',
      threshold: 10,

      pagination: {
        el: '.speakers-v3__current',
        type: 'fraction'
      },
      navigation: {
        nextEl: '.button-arrow--next',
        prevEl: '.button-arrow--prev',
        disabledClass: 'button-arrow--disabled'
      },

      breakpoints: {
        1024: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          speed: 400,
          freeMode: false
        },
        1280: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          speed: 400,
          freeMode: false
        }
      }
    },
    modules: ['pagination', 'navigation', 'freeMode', 'a11y'],
    initCondition: {
      sm: items.length > 1,
      md: items.length > 2,
      lg: items.length > 2,
      xl: items.length > 3
    },
    controls,
    createCallback: () => {
      list.classList.remove('speakers-v3__list--static');
    },
    destroyCallback: () => {
      list.classList.add('speakers-v3__list--static');
    }
  }

  const initSlider = ([entry]) => {
    if (slider !== null || !entry.isIntersecting) {
      return
    }

    slider = new Slider(options)
    slider.init()
  }

  if (xl.matches) {
    const observer = new IntersectionObserver(initSlider)
    observer.observe(wrapper)
  } else {
    new Slider(options).init()
  }

  /* Preview Popup */
  const speakerModel = new SpeakerModel()
  const blockName = document.querySelector('.speakers-v3').dataset.blockName ?? 'Блок без названия'

  list.addEventListener('click', (evt) => {
    evt.preventDefault()

    const card = evt.target.closest('.speakers-v3__item')

    if (card) {
      const hasWorks = card.dataset.works
      const hasWorksValue = JSON.parse(hasWorks)

      new Speaker(card, list, speakerModel, hasWorksValue, blockName).init()
      window.$analytics?.push('teachers-card-click')
    }
  })
}();
