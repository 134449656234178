import { UiButtonVanilla } from '@skillbox/front-box/dist/Buttons/UiButton/UiButtonVanilla'
import { getUiCheckboxFieldTemplate } from '@skillbox/front-box/dist/Inputs/UiCheckboxField/UiCheckboxFieldTemplate'
import { createElement } from '../../utils/render'
import UiModal from '../UiModal/UiModal'

import { lernaSchedule } from './data'
import { ClassName, SuccessHTTPStatusRange } from '../../Form/const'

const getScheduleModalTemplate = (schedule) => {
  return (
    `<div class="lerna-schedule-modal__wrapper ui-typography-group">
      <h3 class="lerna-schedule-modal__title h h--5">
        Спасибо за заявку!
      </h3>

      <p class="lerna-schedule-modal__desc p p--2">
        Когда вам будет удобно ответить на&nbsp;наш звонок?
      </p>

      <form action="/" class="lerna-schedule-modal__form">
        <ul class="lerna-schedule-modal__list">
          ${schedule.map((option) => (
            `<li class="lerna-schedule-modal__item">
              ${getUiCheckboxFieldTemplate({
                fieldName: 'comment[]',
                classes: 'lerna-schedule-modal__checkbox',
                size: 'big',
                fieldText: option.title,
                fieldAttrs: `value="${option.title}"`
              })}
            </li>`
          )).join('')}
        </ul>

        <div class="lerna-schedule-modal__submit">
          <div class="message message__error f f--12">
            Похоже, произошла ошибка при&nbsp;отправке. Попробуйте отправить снова или&nbsp;перезагрузите страницу.

            <button
              class="message__close"
              aria-label="Закрыть сообщение об ошибке"
              type="button"
            >
              <svg
                width="20"
                height="20"
                aria-hidden="true"
              >
                <use href="#ui-close" />
              </svg>
            </button>
          </div>

          ${UiButtonVanilla({
            size: 'big',
            stretchMode: 'sm-max',
            title: 'Подтвердить',
            type: 'submit',
            classes: 'lerna-schedule-modal__button',
            attrs: 'disabled'
          })}
        </div>
      </form>
    </div>`
  )
}

export class LernaScheduleModal extends UiModal {
  constructor(options = {}) {
    const template = createElement(getScheduleModalTemplate(lernaSchedule))

    super(template, {
      wrapperClass: 'lerna-schedule-modal',
      isCenter: true
    })

    this.submitButton = null
    this.formElement = null
    this.closeErrorButton = null
    this.onClose = options.onClose ?? null
  }

  createForm() {
    this.submitButton = this.element.querySelector('.lerna-schedule-modal__button')
    this.formElement = this.element.querySelector('.lerna-schedule-modal__form')
    this.closeErrorButton = this.element.querySelector('.message__close')
    this.checkboxes = [...this.formElement.querySelectorAll(`.${ClassName.CHECKBOX_INPUT}`)]

    this._createButtonSubmitState()
    this._createCloseButtonHandler()
    this._createFormSubmitHandler()
  }

  _createFormSubmitHandler() {
    this.formElement.addEventListener('submit', this._submitFormHandler)
  }

  _removeFormSubmitHandler() {
    this.formElement.removeEventListener('submit', this._submitFormHandler)
  }

  _submitFormHandler = (evt) => {
    evt.preventDefault()

    const formData = new FormData(evt.target)
    formData.append('request_id', window.$lastSendedFormData.requestId)

    this.submitButton.disabled = true

    fetch('/landgen/api/v1/landings/proxy/gateway/thank-you', {
      method: 'POST',
      body: formData,
      headers: { 'Accept': 'application/json' }
    })
      .then((response) => {
        if (
          response.status < SuccessHTTPStatusRange.MIN ||
          response.status > SuccessHTTPStatusRange.MAX
        ) {
          throw new Error(`${response.status}: ${response.statusText}`)
        }

        this.close()
      })
      .catch((e) => {
        e.message = `[Lerna schedule]: ${e.message}`
        this._setError()
        window.Sentry.captureException(e)
      })
      .finally(() => {
        this.submitButton.disabled = false
      })
  }

  _createCloseButtonHandler() {
    this.closeErrorButton.addEventListener('click', this._removeErrorHandler)
  }

  _removeCloseButtonHandler() {
    this.closeErrorButton.removeEventListener('click', this._removeErrorHandler)
  }

  _removeErrorHandler = () => {
    this.formElement.classList.remove('js-error')
  }

  _setError() {
    this.formElement.classList.add('js-error')
  }

  _createButtonSubmitState() {
    this.formElement.addEventListener('change', this._changeCheckboxHandler)
  }

  _removeButtonSubmitState() {
    this.checkboxes.forEach((checkbox) => {
      checkbox.removeEventListener('change', this._changeCheckboxHandler)
    })
  }

  _changeCheckboxHandler = () => {
    const haveOneChecked = this.checkboxes.some((checkbox) => checkbox.checked)

    this.submitButton.disabled = !haveOneChecked
  }

  beforeMounted() {
    this.createForm()
  }

  beforeUnmounted() {
    this._removeFormSubmitHandler()
    this._removeButtonSubmitState()
    this._removeCloseButtonHandler()
    this.onClose?.()
  }
}
