export const pushAnalyticSlidePosition = (swiper) => {
  // название слайдера или название блока в котором переключается слайдер
  const block = swiper.el.closest('[data-slider-name], [data-block-name]');
  const blockName = block ? block.dataset.blockName || block.dataset.sliderName : 'Блок без названия';

  const index = swiper.params.slidesPerGroup > 1
    ? Math.ceil(swiper.realIndex / swiper.params.slidesPerGroup)
    : swiper.realIndex

  window.$analytics && window.$analytics.push('slider-nav', {
    blockName,
    activeSlide: index + 1, // начинается с 0
  });
};
