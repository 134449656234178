export const getCountryCode = async () => {
  try {
    const geoRes = await fetch('https://skillbox.ru/_front/geo/')
    const geo = await geoRes.json()

    return geo.country_code.toLowerCase()
  } catch (e) {
    console.error(`Не удалось получить код страны: ${e}`) // eslint-disable-line no-console
    window.Sentry && window.Sentry.captureException(`Не удалось получить код страны: ${e}`)

    return 'ru'
  }
}
