import Inputmask from 'inputmask';
import intlTelInput from 'intl-tel-input';
import utils from './utils';
import { getCountryCode } from '../../utils/country-code';
import { setCookie, getCookie } from '../../utils/cookies';

let countryCodePhone = getCookie('country_code_phone');
const config = {
  initialCountry: 'auto',
  hiddenInput: 'phone_country_code',
  preferredCountries: ['ru', 'by', 'ua', 'kz', 'uz', 'md', 'am'],
  utilsScript: utils,
  autoHideDialCode: false,
  formatOnDisplay: false,
  nationalMode: false
};

const setCountryCode = async () => {
  if (countryCodePhone) {
    config.initialCountry = countryCodePhone;
    return;
  }

  countryCodePhone = getCookie('country_code') || await getCountryCode();

  config.geoIpLookup = (success) => {
    success(countryCodePhone);
    setCookie('country_code_phone', countryCodePhone);
  }
}

const setPhoneMask = (input, number, countryCode) => {
  const re = new RegExp(`^\\+${countryCode}`, 'g');
  const mask = number.replace(re, '').replace(/\d/g, '#');
  input.autocomplete = 'on';

  Inputmask({
    mask: `+${countryCode}${mask}${countryCode === '359' ? '#{0,1}' : ''}`,
    definitions: {
      '#': {
        validator: '[0-9]'
      }
    },
    usePrototypeDefinitions: false,
    onBeforeMask: function (value) {
      const isNeedRemoveFirstSymbol = countryCode === '7' && value.length >= 11 && value.startsWith('8');

      if (isNeedRemoveFirstSymbol) {
        value = value.substring(1, value.length);
      }

      return value
    },
  }).mask(input);
};

export const initPhoneMask = async (form, input) => {
  await setCountryCode();

  const value = input.value;
  input.value = '';
  config.dropdownContainer = input.parentNode;
  const iti = intlTelInput(input, config);
  input.iti = iti;

  iti.promise.then(() => {
    let countryData = iti.getSelectedCountryData();
    let iso2 = countryData.iso2;
    const countryCode = form.querySelector('[name=phone_country_code]');

    countryCode.value = iso2;
    input.value = value;
    setPhoneMask(input, input.placeholder, countryData.dialCode);

    input.addEventListener('countrychange', () => {
      countryData = iti.getSelectedCountryData();
      iso2 = countryData.iso2;
      countryCode.value = iso2;
      iso2 && setCookie('country_code_phone', countryData.iso2);

      input.value = '';
      iso2 && setPhoneMask(input, input.placeholder, countryData.dialCode);
    });
  })
};
