import { Media } from '../../utils/const'
import { getCookie, setCookie } from '../../utils/cookies'
import { showTrapModal } from '../../../components/popups/v1/TrapModal/scripts'

const MODAL_ACTIVE_CLASSES = '.js-popup--active, .ui-modal'
const TIMEOUT = {
  AFTER_START_SESSION: 40 * 1000,
  WAIT_MOUSELEAVE: 5 * 1000
}

const showSmartPopup = (probability) => {
  const submitHandler = (evt) => {
    const { formData } = evt.sendingResult

    const data = {
      label: 'trial',
      probability,
      requestId: formData.get('request_id')
    }

    window.$analytics?.push('trap-popup-send', data)
  }

  const closeHandler = () => {
    window.$analytics?.push('trap-popup-close', { probability, label: 'trial' })
  }

  showTrapModal({
    hiddenFields: [{ name: 'type', value: 'lead-hunter-trial' }],
    submitHandler,
    closeHandler
  })
}

// Если мы попали сюда, значит window.userSession есть, поэтому нет проверок
export class SmartModalController {
  constructor(showThreshold = 0.45) {
    this.lg = window.matchMedia(`(min-width: ${Media.LG}px)`)
    this.probabilityInterval = [0, showThreshold]

    window.userSession.initPromise.finally(() => {
      this.init()
    })
  }

  init = () => {
    // удаляем старую куку
    if (getCookie('smartPopupCookie')) {
      setCookie('smartPopupCookie', 'remove', { 'max-age': 1 })
    }

    // Проверяем сессиию, запускаем по необходимости
    if (this.getSmartPopupShowedInSession() !== window.userSession.session.id) {
      this.initSmartPopup()

      this.lg.addEventListener('change', this.mediaChangeListener)
      document.body.addEventListener('form-sended', this.setSmartPopupShowedInSession)
    }
  }

  clearTriggerListeners = () => {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }

    document.body.removeEventListener('mouseleave', this.showModal)
  }

  clearFormListener = () => {
    document.body.removeEventListener('form-sended', this.setSmartPopupShowedInSession)
  }

  startDesktop = () => {
    this.timeout = setTimeout(() => {
      document.body.addEventListener('mouseleave', this.showModal)
    }, TIMEOUT.WAIT_MOUSELEAVE)
  }

  startMobile = () => {
    this.timeout = setTimeout(() => {
      this.showModal()
    }, TIMEOUT.AFTER_START_SESSION)
  }

  initSmartPopup = () => {
    this.lg.matches
      ? this.startDesktop()
      : this.startMobile()
  }

  mediaChangeListener = () => {
    this.clearTriggerListeners()
    this.initSmartPopup()
  }

  showModal = () => {
    // Если в этой сессии показывали, то удаляем слушатели и выходим
    if (this.getSmartPopupShowedInSession() === window.userSession.session.id) {
      this.clearTriggerListeners()
      this.clearFormListener()

      return
    }

    // Проверяем наличие открытых модалок
    // Если в момент необходимости показа был открыт какой-то другой попап, перезапускаем AFTER_START_SESSION для мобилки
    // Для десктопа уже будет висеть mouseleave
    if (document.querySelector(MODAL_ACTIVE_CLASSES)) {
      if (!this.lg.matches) {
        this.startMobile()
      }

      return
    }

    const probability = window.userSession.calcScoringSmartPopup()
    const isProbabilityAppropriate = probability > this.probabilityInterval[0] && probability < this.probabilityInterval[1]

    if (isProbabilityAppropriate) {
      showSmartPopup(probability)

      window.$analytics?.push('smartpopup-trial-show', {
        probability: probability,
        label: 'trial'
      })

      this.setSmartPopupShowedInSession()
      this.clearFormListener()
    }

    this.clearTriggerListeners()
  }

  setSmartPopupShowedInSession = () => {
    setCookie('smartPopupShowedInSession', window.userSession.session.id, {}, false)
  }

  getSmartPopupShowedInSession = () => {
    return getCookie('smartPopupShowedInSession')
  }
}
