import { closePopup } from '../popups'
import { AlchemereThanksModal } from './AlchemereThanksModal'
import { createAlchemereLernaThanksModal } from './AlchemereLernaThanksModal'
import { LernaScheduleModal } from './LernaScheduleModal'

export const showAlchemerePopup = (type) => {
  const isLerna = typeof isLernaLanding !== 'undefined' && isLernaLanding // eslint-disable-line

  if (typeof anketologUrl === 'undefined') {
    return null
  }

  // Трекаем открытие анкеты алхимера после оплаты/отправки заявки в форме оплаты
  if (type === 'order') {
    window.$analytics?.push('fl-order', window.$paymentData)
  }

  closePopup()

  if (isLerna) {
    new LernaScheduleModal({
      async onClose() {
        const alchemereLernaThanksModal = await createAlchemereLernaThanksModal()

        alchemereLernaThanksModal.init()
      }
    }).init()
  } else {
    // TODO: временный костыль для ленда sales_management_skillbox_x_tochka (нужно будет удалить когда ленд удалят)
    type = location.pathname.includes('sales_management_skillbox_x_tochka') ? 'tochka' : type

    // NOTICE: https://jira.skillbox.pro/browse/WEBDEV-174335
    const testedCourseSlug = 'product-management-training'
    const isUniqueAnketologTest = location.pathname.includes(testedCourseSlug)

    new AlchemereThanksModal(type, isUniqueAnketologTest ? 'https://ankt.cc/QvIcR0' : anketologUrl).init() // eslint-disable-line
  }
}
