import {createElement} from './render'

class AbstractView {
  constructor() {
    if (new.target === AbstractView) {
      throw new Error('Cannot instantiate AbstractView, only concrete one');
    }

    this._element = null;
  }

  getTemplate() {
    throw new Error('Template is required');
  }

  getElement() {
    if (this._element === null) {
      this._element = this.render();
      this.bind();
    }

    return this._element;
  }

  render() {
    return createElement(this.getTemplate());
  }

  bind() {}

  unbind() {}

  unrender() {
    this.unbind();
    this.getElement().remove();
    this._element = null;
  }
}

export default AbstractView;
