import { TimerBase } from './TimerBase'

export class Timer {
  constructor({ time, timerWrapper, tickCallback }) {
    this.timerWrapper = timerWrapper
    this.timerBlock = this.timerWrapper.querySelector('[data-type=timer]') ?? this.timerWrapper

    if (!this.timerBlock) {
      return
    }

    this.tick = this.tick.bind(this)
    this.destroy = this.destroy.bind(this)

    this.timer = new TimerBase({
      time,
      tick: tickCallback || this.tick,
      ended: this.destroy
    })

    this.timer.init()
  }

  tick({ days, daysWord, hours, minutes, seconds }) {
    this.timerBlock.innerHTML = `${days ? `${days}&nbsp;${daysWord}&nbsp;` : ''}${hours}:${minutes}:${seconds}`
  }

  destroy() {
    this.timerWrapper.remove()
    this.ended()
  }

  ended() {}
}
