import {ClassName} from '../const';
import {ValidityActions, ValidityShowMode} from './rules';

export default class FormValidator {
  constructor(form) {
    this._form = form;
    this._errors = [];
  }

  _validate(value, ruleName, ruleParams, input) {
    const action = ValidityActions[ruleName];

    if (action) {
      const validationResult = action.validate(value, ruleParams, input);

      (typeof validationResult === 'string') && this._errors.push({
        ruleName,
        message: validationResult
      });
    } else {
      window.Sentry.captureException(`Передано несуществующее правило: ${ruleName}`);
      console.error(`Передано несуществующее правило: ${ruleName}`); // eslint-disable-line no-console
    }
  }

  _getCurrentErrors(errors, event) {
    return errors.filter(error => ValidityShowMode[error.ruleName].includes(event));
  }

  check(field, input, ruleParams, event) {
    if (input.disabled || !ruleParams) {
      return true;
    }

    this._errors = [];
    const value = input.value.trim();

    Object.keys(ruleParams).forEach((ruleName) => {
      this._validate(value, ruleName, ruleParams, input);
    });

    const currentErrors = this._getCurrentErrors(this._errors, event);

    if (input.type === 'checkbox') {
      return this._verifyBox(field, currentErrors);
    }

    return this._verifyField(field, currentErrors);
  }

  _verifyField(field, currentErrors) {
    const error = field.querySelector(`.${ClassName.MESSAGE_ERROR}`);

    if (!currentErrors.length) {
      field.classList.remove(ClassName.FIELD_ERROR);
    } else {
      error.textContent = currentErrors[0].message;
      field.classList.add(ClassName.FIELD_ERROR);
    }

    return !this._errors.length;
  }

  _verifyBox(field, currentErrors) {
    const content = field.querySelector(`.${ClassName.CHECKBOX_CONTENT}`);

    !currentErrors.length
      ? content.classList.remove(ClassName.CHECKBOX_ERROR)
      : content.classList.add(ClassName.CHECKBOX_ERROR);

    return !this._errors.length;
  }

  reset(field) {
    this._errors = [];
    field.classList.remove(ClassName.FIELD_ERROR);
  }
}
