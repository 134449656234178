import Form from '../Form/Form';
import { defaultRules } from '../Form/default-rules';
import { getVkAuthModel } from './VKConnect';

export const formsInit = async () => {
  const vkConnectContainers = document.querySelectorAll('[data-auth="vk-connect"]');
  const vkAuthModel = await getVkAuthModel(vkConnectContainers);
  const forms = document.querySelectorAll('.form:not(.form--modal)');

  forms.forEach((form) => {
    const blockName = form.closest('[data-block-name]')?.dataset.blockName || 'Блок без названия';
    new Form({
      form,
      vkAuthModel,
      rules: defaultRules,
      blockName
    }).init();
  });
};
