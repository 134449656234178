import './style.scss'

import { smartPopupInit } from '../../../js/components/SmartPopup'

export default !function () {
  try {
    smartPopupInit()
  } catch (e) {
    window.Sentry.captureException(new Error(`[smart-popup]: ошибка инициализации smart-popup ${e.message}`))
  }
}();
