import { Panzoom } from '@fancyapps/ui'
import { createElement } from '../../utils/render'
import { Slider } from '../Slider/Slider'
import UiModal from '../UiModal/UiModal'
import { getDetailsModalTemplate } from './details-modal.template'

export class DetailsModal extends UiModal {
  constructor(data, activeIndex = 0, blockName) {
    const template = createElement(getDetailsModalTemplate(data, blockName))

    super(template, {
      template: 'clear',
      wrapperClass: 'details-modal',
      buttonTheme: 'main-white'
    })

    this._slider = null
    this._activeIndex = activeIndex

    this._zoomElement = null
    this._zoomControls = null
    this._picturesElements = []
    this._panzoom = {}
    this._blockName = blockName

    this._clickZoomHandler = this._clickZoomHandler.bind(this)
    this._initZoom = this._initZoom.bind(this)
  }

  async beforeMounted() {
    this.element.dataset.theme = 'dark'
    this._zoomControls = this.element.querySelector('.details-modal__zoom')
    this._zoomElement = this._zoomControls.querySelector('.details-modal__size')
    this._picturesElements = this.element.querySelectorAll('.details-modal__picture:not(.details-modal__picture--video)')
  }

  mounted() {
    this._picturesElements.forEach(this._initZoom)
    this._zoomControls.addEventListener('click', this._clickZoomHandler)
    this._initSwiper()
  }

  unmounted() {
    this._zoomControls.removeEventListener('click', this._clickZoomHandler)
    this._slider?.destroy()
    this._destroyZoom()
  }

  _initSwiper() {
    const options = {
      wrapper: this.element.querySelector('.details-modal__slider'),
      settings: {
        slidesPerView: 1,
        spaceBetween: 8,
        loop: false,
        effect: 'slide',
        focusableElements: 'img, picture',

        slideClass: 'details-modal__item',
        wrapperClass: 'details-modal__list',

        pagination: {
          el: '.details-modal__count',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.details-modal__button--next',
          prevEl: '.details-modal__button--prev',
          disabledClass: 'details-modal__button--disabled'
        },
        on: {
          init: (evt) => {
            if (this._activeIndex !== 0) {
              evt.slideTo(this._activeIndex, 0)
            }
          },
          activeIndexChange: (evt) => {
            const zoomData = this._panzoom[this._activeIndex]

            // Сбрасываем зум для предыдущего слайда
            if (zoomData) {
              this._panzoom[this._activeIndex].zoomTo()
              this._updateZoomSize()
              evt.allowTouchMove = true
            }

            this._activeIndex = evt.activeIndex

            // Устанавливаем disabled для текущего слайда
            const newZoomData = this._panzoom[this._activeIndex]
            this._setDisabledZoom(newZoomData)
          }
        }
      },
      modules: ['navigation', 'pagination'],
      initCondition: {
        sm: true,
        md: true,
        lg: true,
        xl: true
      }
    }

    this._slider = new Slider(options)
    this._slider.init()
  }

  async _initZoom(container, id) {
    this._panzoom[id] = new Panzoom(container, {
      friction: 0,
      bounce: false,
      click: 'toggleMax',
      viewport: container.querySelector('.details-modal__viewport'),
      content:  container.querySelector('.details-modal__content'),
      classes: {
        content: 'details-modal__content',
        isDraggable: 'details-modal__picture--draggable',
        isDragging: 'details-modal__picture--dragging'
      },
      on: {
        beforeLoad: (evt) => {
          const { maxScale, minScale } = evt

          if (maxScale > minScale) {
            evt.container.classList.add('details-modal__picture--zoom')
          }
        },
        endAnimation: (evt) => {
          if (this._activeIndex !== id) {
            return
          }

          const { container, scale, maxScale } = evt

          this._updateZoomSize(scale)
          this._setDisabledZoom({ scale, maxScale: Math.round(maxScale) })

          container && scale !== 1
            ? container.classList.add('details-modal__picture--zoomed')
            : container.classList.remove('details-modal__picture--zoomed')

          this._slider && (this._slider.slider.allowTouchMove = Math.round(scale) === 1)
        },
        click: () => {
          window.$analytics?.push('details-modal-zoom-change', { blockName: this._blockName })
        }
      }
    })
  }

  _updateZoomSize(scale = 1) {
    this._zoomElement.textContent = `${Math.round(scale)}x`
  }

  _setDisabledZoom(data) {
    this._zoomControls.querySelector('[data-zoom=out]').disabled = !data || Math.round(data.scale) === 1
    this._zoomControls.querySelector('[data-zoom=in]').disabled = !data || Math.round(data.scale) === data.maxScale
  }

  _clickZoomHandler(evt) {
    const target = evt.target.closest('.details-modal__zoom-btn')

    if (target) {
      const panzoom = this._panzoom[this._activeIndex]

      switch (target.dataset.zoom) {
        case 'in':
          panzoom.zoomTo(panzoom.scale + 1)
          break
        case 'out':
          panzoom.zoomTo(panzoom.scale - 1)
          break
      }

      window.$analytics?.push('details-modal-zoom-change', { blockName: this._blockName })
    }
  }

  _destroyZoom() {
    Object.values(this._panzoom).forEach(z => z.destroy())
    this._panzoom = {}
  }
}
