const getWord = (word, count) => {
  const num = count % 100;
  const mod = num % 10;

  if (num !== 11 && mod === 1) {
    return word[0];
  } else if (mod >= 2 && mod <= 4 && (num < 10 || num > 20)) {
    return word[1];
  } else {
    return word[2];
  }
};

const defaultTranslations = {
  "errors": {
    "required": "Поле необходимо заполнить",
    "phoneFormat": {
      "invalid": "Неправильный номер телефона",
      "includeLatin": "Поле не может содержать латиницу",
      "includeCyrillic": "Поле не может содержать кириллицу",
      "includeForbiddenCharacters": "Поле не может содержать"
    },
    "phoneMinLength": "Неправильный номер телефона",
    "phoneMaxLength": "Неправильный номер телефона",
    "min": "Поле слишком короткое",
    "max": "Поле слишком длинное",
    "onlyLetters": "Поле не должно содержать цифры или спец.символы",
    "email": "Неправильная электронная почта"
  },
  "cookie": {
    "approval": "Пользуясь нашим сайтом, вы&nbsp;соглашаетесь с&nbsp;тем, что",
    "employ": "мы&nbsp;используем cookies",
    "ok": "Окей"
  },
  "pluralization": {
    "day": "день|дня|дней",
    "step": "шаг|шага|шагов"
  },
  "form": {
    "button": {
      "consultation": "Записаться",
      "pay": "Оплатить",
      "credit": "Перейти к оформлению",
      "cloudpayments": "Оплатить"
    }
  }
};

export const checkTranslations = () => {
  if (!window.translations || !window.translations.form || !window.translations.pluralization || !window.translations.errors || !window.translations.errors.required) {
    console.error(`Объект window.translations: ${JSON.stringify(window.translations)}`); // eslint-disable-line no-console
    window.Sentry.captureException(`Объект window.translations: ${JSON.stringify(window.translations)}`);
    window.translations = defaultTranslations;
  }
}

export const translate = (key, replace = {}) => {
  let translation = key.split('.').reduce((t, i) => t[i] || null, window.translations);

  for (const placeholder in replace) {
    if (Object.prototype.hasOwnProperty.call(replace, placeholder)) {
      translation = translation.replace(`:${placeholder}`, replace[placeholder]);
    }
  }

  return translation;
};

export const translatePluralization = (key, count = 1, replace = {}) => {
  let translation = key.split('.').reduce((t, i) => t[i] || null, window.translations).split('|');

  translation = getWord(translation, count);

  for (const placeholder in replace) {
    if (Object.prototype.hasOwnProperty.call(replace, placeholder)) {
      translation = translation.replace(`:${placeholder}`, replace[placeholder]);
    }
  }

  return translation;
};
