/* eslint-disable-next-line */
const isDataLayer = !!window.dataLayer && !isGbHost;

const closePopupGtmTag = () => {
  if (isDataLayer) {
    window.dataLayer.push({
      event: 'application.close',
      details: {
        category: 'application',
        action: 'close'
      }
    });
  }
};

/*eslint-disable */
const initPagePush = () => {
  if (isDataLayer && !!autopayment && amoId) {
    window.dataLayer.push({
      event: 'coursepage.view',
      details: {
        'courseID.konstructor': amoId,
        'courseprice.konstructor': monthlyPayment
      }
    });
  }
};
/*eslint-enable */

const accordionGtmTag = (eventName, value) => {
  if (isDataLayer) {
    const obj = {};

    obj.event = `${eventName}_view`;
    obj[`dl_${eventName}_name`] = value;  // зачем то разные ключи решили сделать

    window.dataLayer.push(obj);
  }
};

const clickButtonGtmTag = (clickType) => {
  if (isDataLayer) {
    switch (clickType) {
      case 'lead':
        window.dataLayer.push({
          event: 'application.click',
          details: {
            category: 'application',
            action: 'click'
          }
        });
        break;

      case 'consult':
        window.dataLayer.push({
          event: 'callback.click',
          details: {
            category: 'callback',
            action: 'click'
          }
        });
        break;

      case 'enroll':
        window.dataLayer.push({
          event: 'enroll.course',
          details: {
            category: 'enroll.course',
            action: 'click'
          }
        });
        break;
    }
  }
};

const submitFormGtmTag = (submitType, requestId) => {
  if (isDataLayer) {
    switch (submitType) {
      case 'lead':
        window.dataLayer.push({
          event: 'application.submit',
          details: {
            category: 'application',
            action: 'send',
            request_id: requestId
          }
        });
        break;

      case 'consult':
        window.dataLayer.push({
          event: 'callback.submit',
          details: {
            category: 'callback',
            action: 'send',
            request_id: requestId
          }
        });
        break;

      // события для триала в смартпопапах вместо lead all
      case 'smartpopup-trial':
        window.$analytics?.push('smartpopup-trial-submit')
        break;
    }
  }
};

const successFormGtmTag = (submitType, requestId) => {
  if (isDataLayer) {
    switch (submitType) {
      case 'lead':
        window.dataLayer.push({
          event: 'application.backend_success',
          details: {
            category: 'application_backend_success',
            action: requestId
          }
        });
        break;

      case 'consult':
        window.dataLayer.push({
          event: 'callback.backend_success',
          details: {
            category: 'callback_backend_success',
            action: requestId
          }
        });
        break;
    }
  }
};

const failFormGtmTag = (submitType, {requestId, phone, email, errorCode, response: error}) => {
  const response = JSON.stringify(error);

  if (isDataLayer) {
    switch (submitType) {
      case 'lead':
        window.dataLayer.push({
          event: 'application.backend_fail',
          details: {
            request_id: requestId,
            phone,
            email,
            error_code: errorCode,
            response
          }
        });
        break;

      case 'consult':
        window.dataLayer.push({
          event: 'callback.backend_fail',
          details: {
            request_id: requestId,
            phone,
            email,
            error_code: errorCode,
            response
          }
        });
        break;
    }
  }
};

const showTrailerGtmEvent = (gtmEvent) => {
  if (window.dataLayer && gtmEvent) {
    window.dataLayer.push({'event' : gtmEvent})
  }
};

export {
  closePopupGtmTag,
  clickButtonGtmTag,
  submitFormGtmTag,
  successFormGtmTag,
  failFormGtmTag,
  showTrailerGtmEvent,
  accordionGtmTag,
  initPagePush
};
