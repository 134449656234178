import { createElement } from '../../utils/render';

const UiBackButtonTemplate = () => {
  return (
    `<div class="back-button ui-container">
      <button class="ui-back-button">
        <span class="ui-back-button__icon">
          <svg
            width="16"
            height="16"
            aria-hidden="true"
          >
            <use href="#ui-chevron-left"/>
          </svg>
        </span>

        <span class="f f--16">
          Назад
        </span>
      </button>
    </div>`
  )
}

export const addBackButton = () => {
  if (history.length > 1 && document.referrer.includes('skillbox.ru') && location.search.includes('from=skillbox')) {
    const main = document.querySelector('main');
    const backButtonBlock = createElement(UiBackButtonTemplate());

    main.prepend(backButtonBlock);

    const button = backButtonBlock.querySelector('.ui-back-button');

    button.addEventListener('click', () => {
      history.back()
    });
  }
}
