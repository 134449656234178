import { scrollTo } from "./scrollToWithCallback";
import { clickButtonGtmTag } from '../libs/gtm';
import { showPopup } from './popups';
import { debounce } from '../utils/debounce';

const scrollWithBounce = debounce(scrollTo, 200);

export const scrollToId = (btn) => {
  const elementScroll = document.querySelector(btn.getAttribute('href')) || document.querySelector('#tariffs');

  if (!elementScroll) {
    return;
  }

  const namedElement = elementScroll.closest('[data-block-name]');

  window.programlyScrollingTo = (namedElement && namedElement.dataset.blockName) || 'not-named-block';
  scrollWithBounce(elementScroll, () => window.programlyScrollingTo = null);
};

const showCurrentPopup = (btn) => {
  const popupName = btn.dataset.popup

  if (!popupName) {
    return
  }

  const popup = document.querySelector(`#${popupName}`);

  if (popup && !popup.classList.contains('js-popup--active')) {
    /* eslint-disable-next-line */
    !isGbHost && window.$analytics?.push('fl-show-form')
    showPopup(popup);
  }
};

export const buttonsHandlersInit = () => {
  const buttons = document.querySelectorAll('[data-action-button]');

  buttons.forEach((btn) => {
    btn.addEventListener('click', (evt) => {
      evt.preventDefault();

      const buttonType = btn.dataset.gtm;
      buttonType && clickButtonGtmTag(buttonType);

      !isGbHost && window.$analytics?.push('fl-show-form') // eslint-disable-line

      switch (btn.dataset.actionButton) {
        case 'scroll':
          scrollToId(btn);
          break;
        case 'popup':
          showCurrentPopup(btn);
          break;
      }
    })
  })
};
