const getDomainForCookie = () => {
  const topLevelDomain = window.location.host
    .split('.')
    .slice(-2)
    .join('.');
  const isHavePort = !!topLevelDomain.match(/.*?(:)\d*/);

  // удаляем порт и Не ставим точку для доменов типа localhost:3000
  if (isHavePort) {
    return topLevelDomain.replace(/(:)\d*/g, '');
  }

  return `.${topLevelDomain}`;
};

export const setCookie = (name, value, options = {}, isTopDomainCookie = true) => {
  options = {
    path: '/',
    'max-age': 60 * 60 * 24 * 365,
    ...options
  };

  if (isTopDomainCookie) {
    options.domain = getDomainForCookie()
  }

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];

    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
};

export const getCookie = (name) => {
  /* eslint-disable*/
  const matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ));
  /* eslint-enabled*/

  return matches ? decodeURIComponent(matches[1]) : undefined;
};
