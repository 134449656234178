import { translatePluralization } from '../../utils/translations'

export class TimerBase {
  constructor({ time, tick, ended }) {
    this.tick = tick
    this.ended = ended

    this.timeLeft = new Date(time) - new Date()
    this.interval = null
    this.timerValues = {
      seconds: 0,
      minutes: 0,
      hours: 0,
      days: 0
    }
  }

  init() {
    this.initTimer(this.timeLeft)

    this.interval = setInterval(() => {
      this.calcTime()
      this.tick(this.timer)
    }, 1000)
  }

  destroy() {
    clearInterval(this.interval)
    this.ended()
  }

  initTimer(timeLeft) {
    if (timeLeft <= 0) {
      this.destroy()
      return
    }

    let sec = timeLeft / 1000
    let min = sec / 60
    let hours = min / 60
    let days = hours / 24

    days = Math.floor(days)
    hours = Math.floor(hours - (days * 24))
    min = Math.floor(min - (hours * 60) - (days * 1440))
    sec = Math.floor(sec - (min * 60) - (hours * 3600) - (days * 86400))

    this.timerValues.days = days
    this.timerValues.hours = hours
    this.timerValues.minutes = min
    this.timerValues.seconds = sec
  }

  calcTime() {
    const timerValues = this.timerValues

    if (timerValues.seconds) {
      --timerValues.seconds
    } else if (timerValues.minutes) {
      --timerValues.minutes
      timerValues.seconds = 59
    } else if (timerValues.hours) {
      --timerValues.hours
      timerValues.minutes = 59
      timerValues.seconds = 59
    } else if (timerValues.days) {
      --timerValues.days
      timerValues.hours = 23
      timerValues.minutes = 59
      timerValues.seconds = 59
    } else {
      this.destroy()
    }
  }

  getTimeStringValue(value) {
    return value >= 10 ? value : `0${value}`
  }

  get timer() {
    const days = this.timerValues.days
    const daysWord = translatePluralization('pluralization.day', days)
    const hours = this.getTimeStringValue(this.timerValues.hours)
    const minutes = this.getTimeStringValue(this.timerValues.minutes)
    const seconds = this.getTimeStringValue(this.timerValues.seconds)

    return {
      days,
      daysWord,
      hours,
      minutes,
      seconds
    }
  }
}
