export const Method = {
  POST: 'POST'
};

export const SuccessHTTPStatusRange = {
  MIN: 200,
  MAX: 299
};

export const FormType = {
  LEAD: 'lead',
  PRESENTATION:'presentation',
  CONSULT: 'consult',
  AUTOPAYMENT: 'autopayment',
  PAY: 'pay'
};

export const PaymentType = {
  CONSULTATION: 'consultation',
  CLOUDPAYMENTS: 'cloudpayments'
};

export const ClassName = {
  LOADING: 'ui-button--loading',
  ACTIVE: 'ui-field--active',
  ERROR: 'js-error',
  SUCCESS: 'js-success',
  SUCCESS_CLOSE: 'ui-icon-button',
  MESSAGE_ERROR: 'ui-field__desc',
  FIELD: 'ui-field',
  FIELD_ERROR: 'ui-field--error',
  INPUT: 'ui-field__input',
  LABEL: 'ui-field__name',
  CHECKBOX: 'ui-checkbox-field',
  CHECKBOX_INPUT: 'ui-checkbox-field__input',
  CHECKBOX_CONTENT: 'ui-checkbox-field__value',
  CHECKBOX_ERROR: 'ui-checkbox-field__value--error'
};
