import { UtmService } from '@skillbox/utm-service'
// eslint-disable-next-line no-undef
const isProduction = typeof environment === 'undefined' && environment === 'production'

window.UTM_SERVICE_INITED = true

window.utmService = new UtmService({
  baseUrl: !isProduction && 'https://utm.wbd.dev.skbx.pro/'
})

window.utmService.init()
