import { lazyVideoObserver } from '@skillbox/front-box/src/utils/lazyloadVideo'

export const videoObserverInit = () => {
  const videos = document.querySelectorAll('.lazy-video')

  videos.forEach(video => {
    const observer = lazyVideoObserver()
    video && observer?.observe(video)
  })
}
