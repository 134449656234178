import {translate} from '../../utils/translations';

export const ValidityShowMode = {
  checked: ['change', 'submit'],
  required: ['submit'],
  min: ['change', 'submit'],
  max: ['change', 'submit'],
  email: ['change', 'submit'],
  phoneFormat: ['change', 'submit'],
  phoneMinLength: ['change', 'submit'],
  phoneMaxLength: ['input', 'change', 'submit'],
  onlyLetters: ['input', 'change', 'submit']
};

export const ValidityActions = {
  checked: {
    validate(value, _, input) {
      if (!input.checked) {
        return translate('errors.required');
      }

      return true;
    }
  },
  required: {
    validate(value) {
      if (value === '') {
        return translate('errors.required');
      }

      return true;
    }
  },
  phoneFormat: {
    validate(value, _, input) {
      if (input.iti) {
        return !input.iti.isValidNumber()
          ? translate('errors.phoneFormat.invalid')
          : true;
      } else {
        const isValid = value.match(/^[+()]*\d[- +()\d]*$/g);

        if (isValid) {
          return true;
        }

        const isIncludeLatin = value.match(/[a-zA-Z]/g);

        if (isIncludeLatin) {
          return translate('errors.phoneFormat.includeLatin');
        }

        const isIncludeCyrillic = value.match(/[а-яёА-ЯЁ]/g);

        if (isIncludeCyrillic) {
          return translate('errors.phoneFormat.includeCyrillic');
        }

        const clearValue = value.replace(/[- +()]/g, '');
        const forbiddenCharactersList = clearValue.match(/\D/g);

        if (forbiddenCharactersList) {
          const forbiddenCharacters = forbiddenCharactersList
            .map(character => character[0])
            .filter((item, pos) => forbiddenCharactersList.indexOf(item) === pos)
            .join(' ');

          return `${translate('errors.phoneFormat.includeForbiddenCharacters')} ${forbiddenCharacters}`;
        }
      }

      return true;
    }
  },
  phoneMinLength: {
    validate(value, params) {
      const clearedPhone = value.replace(/\D/g, '');

      if (clearedPhone.length < params.phoneMinLength) {
        return translate('errors.phoneMinLength');
      }

      return true;
    }
  },
  phoneMaxLength: {
    validate(value, params) {
      const clearedPhone = value.replace(/\D/g, '');

      if (clearedPhone.length > params.phoneMaxLength) {
        return translate('errors.phoneMaxLength');
      }

      return true;
    }
  },
  email: {
    validate(value) {
      /* eslint-disable */
      const emailRegex = /^(?:[a-z-яА-ЯёЁ0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z-яА-ЯёЁ0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z-яА-ЯёЁ0-9](?:[a-z-яА-ЯёЁ0-9-]*[a-z-яА-ЯёЁ0-9])?\.)+[a-z-яА-ЯёЁ0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
      /* eslint-enable */
      const isValid = emailRegex.test(value);

      if (!isValid) {
        return translate('errors.email');
      }

      return true;
    }
  },
  onlyLetters: {
    validate(value) {
      const isValid = /^[A-Za-zА-Яа-яЁё\s]+$/.test(value);

      if (value !== '' && !isValid) {
        return translate('errors.onlyLetters');
      }

      return true;
    }
  },
  min: {
    validate(value, params) {
      if (value.length < params.min) {
        return translate('errors.min');
      }

      return true;
    }
  },
  max: {
    validate(value, params) {
      if (value.length > params.max) {
        return translate('errors.max');
      }

      return true;
    }
  }
};
