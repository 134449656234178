import './style.scss';

export default (async function () {
  const { gsap } = await import('gsap');
  const { ScrollTrigger } = await import('gsap/dist/ScrollTrigger');

  gsap.registerPlugin(ScrollTrigger);

  const animateRow = (element, reverse) => {
    const direction = reverse ? '25%' : '-25%';

    return gsap.to(element, {
      scrollTrigger: {
        trigger: '.geography',
        start: '-400px bottom', // первое значение — от элемента, второе — от вьюпорта
        end: 'bottom -400px',
        scrub: true,
        toggleActions: 'restart pause reverse pause'
      },
      x: direction,
      ease: 'none',
      duration: 4000
    });
  }

  animateRow('.geography__list--top');
  animateRow('.geography__list--mid', true);
  animateRow('.geography__list--bottom');

  // Обновляет анимацию при изменении высоты страницы
  const resizeObserver = new ResizeObserver(() => {
    ScrollTrigger.refresh();
  });

  resizeObserver.observe(document.body);
})();
