import './style.scss'
import { Media } from '../../../js/utils/const'
import { DetailsModal } from '../../../js/components/DetailsModal/DetailsModal'

export default !function () {
  const block = document.querySelector('.portfolio-v2')

  if (!block) {
    return
  }

  // Handle load more
  let items = [...block.querySelectorAll('.portfolio-v2__item--card:nth-child(n + 4)')]
  const loadMoreItem = block.querySelector('.portfolio-v2__item--load-more')
  const loadMoreButton = loadMoreItem.querySelector('.portfolio-v2__load-more')

  let mobileCounter = 0
  const mobileCardsPerView = 3
  const partsCounter = Math.ceil(items.length / mobileCardsPerView)

  const mobileLoadMoreHandler = () => {
    const itemsToShow = items.splice(0, mobileCardsPerView)
    itemsToShow.forEach(item => item.style.display = 'block')

    mobileCounter++

    if (mobileCounter === partsCounter) {
      loadMoreItem.remove()
    }
  }

  const desktopLoadMoreHandler = () => {
    items.forEach(item => item.style.display = 'block')
    loadMoreItem.remove()
  }

  // Handle detailed view
  let mediaData = []
  const blockName = block.dataset.blockName ?? 'Блок без названия'
  const list = block.querySelector('.portfolio-v2__list')

  const setLoader = (isLoading) => {
    block.classList.toggle('portfolio-v2--loading', isLoading)
  }

  const listClickHandler = async (evt) => {
    const card = evt.target.closest('.portfolio-v2__item--card')

    if (card) {
      const id = +card.dataset.id
      window.$analytics?.push('portfolio-card-click')

      setLoader(true)

      try {
        if (!mediaData.length) {
          // eslint-disable-next-line
          const response = await fetch(`/landgen/api/v1/landing/${landingInfo.landing_ID}/country/${landingInfo.countrySlug}/language/${landingInfo.languageSlug}/portfolio`)

          if (!response.ok) {
            await Promise.reject(response)
          }

          const { data } = await response.json()

          mediaData = data
        }

        mediaData.length && new DetailsModal(mediaData, id, blockName).init()
      } catch (e) {
        e.message = `[media-gallery] ${e.message}`
        window.Sentry.captureException(e)
      } finally {
        setLoader(false)
      }
    }
  }

  list.addEventListener('click', listClickHandler)

  // Mediachange
  const smMax = window.matchMedia(`(max-width: ${Media.SM_MAX}px)`)
  let buttons = block.querySelectorAll('.portfolio-v2__button')

  const tabletMediaChangeListener = () => {
    const minMobileCards = 3
    const firstMinTabletCards = 6
    const secondMinTabletCards = 9
    const mobileNoLoadButton = smMax.matches && buttons.length <= minMobileCards
    const desktopNoLoadButton = !smMax.matches && (buttons.length <= firstMinTabletCards || buttons.length === secondMinTabletCards)

    if (mobileNoLoadButton || desktopNoLoadButton) {
      loadMoreItem.remove()
    } else if (smMax.matches) {
      loadMoreButton.removeEventListener('click', desktopLoadMoreHandler)
      loadMoreButton.addEventListener('click', mobileLoadMoreHandler)
    } else {
      loadMoreButton.removeEventListener('click', mobileLoadMoreHandler)
      loadMoreButton.addEventListener('click', desktopLoadMoreHandler)
    }
  }

  tabletMediaChangeListener()
  smMax.addEventListener('change', tabletMediaChangeListener)
}()
