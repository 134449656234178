export default () => {
  let lastSentPoint = null

  const scrollHandler = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.body.offsetHeight;
    const winHeight = window.innerHeight;
    const scrollPercent = scrollTop / (docHeight - winHeight);
    const scrollPercentRounded = Math.round(scrollPercent * 100);

    const remainder = scrollPercentRounded % 10
    const integer = (scrollPercentRounded - remainder)

    if (integer > 0 && integer < 100 && integer !== lastSentPoint) {
      lastSentPoint = integer
      window.$analytics && window.$analytics.push('page-scrolled-to', { percent: integer })
    }
  }

  document.addEventListener('scroll', scrollHandler)
}
