import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { render, createElement } from '../../utils/render';
import AbstractView from '../../utils/abstract-view';

import {
  createAnketologTemplate,
  createPollTemplate,
  createLoaderTemplate
} from './anketolog.template';

const TIMEOUT_LIMIT = 2000;

export class Anketolog extends AbstractView {
  constructor(url) {
    super();

    this._root = document.body;
    this._data = window.$paymentData;
    this._url = url;
    this._timeout = null;

    this._clickCloseHandler = this._clickCloseHandler.bind(this);
    this._escPressHandler = this._escPressHandler.bind(this);

    render(this._root, this.getElement());
    disableBodyScroll(this.getElement());
    document.body.classList.add('ios-safari-overflow'); // fix for iOS

    this._contentWrapper = this.getElement().querySelector('.form-popup__content');
    this._loaderElement = null;
    this._surveyElement = null;

    window.$analytics?.push('thankyou-viewed', { label: 'form_i' });

    this.init();
  }

  init() {
    this._startAwaitAnketolog();

    render(this._contentWrapper, this._loaderElement);
    render(this._contentWrapper, this._surveyElement);
  }

  getTemplate() {
    return createAnketologTemplate();
  }

  get src() {
    return `${this._url}?request_id=${this._data.requestId}`;
  }

  _startAwaitAnketolog() {
    this._loaderElement = createElement(createLoaderTemplate());
    this._surveyElement = createElement(createPollTemplate(this.src));

    this._timeout = setTimeout(() => {
      this._loaderElement?.remove();

      this._stopAwaitAnketolog()
    }, TIMEOUT_LIMIT)
  }

  _stopAwaitAnketolog() {
    clearTimeout(this._timeout)
    this._timeout = null
  }

  _closeAnketologPopup() {
    enableBodyScroll(this.getElement());
    document.body.classList.remove('ios-safari-overflow'); // fix for iOS

    window.$analytics?.push('thankyou-closed', { label: this._thanksElement ? 'End' : 'form_i' })

    this.unrender();
  }

  _clickCloseHandler() {
    this._closeAnketologPopup();
  }

  _escPressHandler(evt) {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      this._closeAnketologPopup();
    }
  }

  bind() {
    this.getElement().querySelector('.form-popup__close')?.addEventListener('click', this._clickCloseHandler);
    document.addEventListener('keydown', this._escPressHandler);
  }

  unbind() {
    this.getElement().querySelector('.form-popup__close')?.removeEventListener('click', this._clickCloseHandler);
    document.removeEventListener('keydown', this._escPressHandler);
  }
}
