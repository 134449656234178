import { getCookie } from '../../utils/cookies'

const getCustomDimensionsForLanding = () => {
  // Глобальные параметры лендинга
  /* eslint-disable-next-line */
  const info = landingInfo

  const customDimensionsForLanding = {
    [`${info.type}_id`]: info.nomenclature_ID,
    [`${info.type}_title`]: info.title
  }

  const auid = getCookie('auid')

  if (auid) {
    Object.assign(customDimensionsForLanding, { auid: auid });
  }

  return customDimensionsForLanding
}

export default {
  'form-submit': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Submit form',
      ...getCustomDimensionsForLanding()
    })
  },
  'block-visible-timing': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: data.action,
      label: data.slug,
      visibility_time: data.timeVisible,
      block_value: data.showCount,
      dl_block_name: data.name,
      ...getCustomDimensionsForLanding()
    })
  },
  'trailer-show': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Trailer show',
      ...getCustomDimensionsForLanding()
    })
  },
  'slider-nav': (data, { provider }) => {
    const { activeSlide, blockName } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Carousel scrolling',
      label: activeSlide,
      dl_block_name: blockName,
      ...getCustomDimensionsForLanding()
    })
  },
  'program-accordion-click': (data, { provider }) => {
    const { text } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Program shown',
      label: text,
      ...getCustomDimensionsForLanding()
    })

    // TODO отрефакторить
    provider.$gtm.push({
      event: 'module_view',
      dl_module_name: text
    });
  },
  'faq-accordion-click': (data, { provider }) => {
    const { text } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'FAQ shown',
      label: text,
      ...getCustomDimensionsForLanding()
    })

    // TODO отрефакторить
    provider.$gtm.push({
      event: 'faq_view',
      dl_faq_name: text
    });
  },
  'universal-events-accordion-click': (data, { provider }) => {
    const { text } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Universal FAQ accordions shown',
      label: text,
      ...getCustomDimensionsForLanding(),
      context: {
        ...getCustomDimensionsForLanding()
      }
    })
  },
  'eng-gift-click': (data, { provider }) => {
    // TODO отрефакторить
    provider.$gtm.push({
      event: 'eng_click'
    });
  },
  'start-screen-conditions-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicking terms of the promotion',
      ...getCustomDimensionsForLanding()
    })
  },
  'start-screen-tooltip-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicking tags',
      ...getCustomDimensionsForLanding()
    })
  },
  'start-screen-details-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'More about skillbox button clicked',
      ...getCustomDimensionsForLanding()
    })
  },
  'consult-form-send': (data, { provider }) => {
    const { blockName, requestId } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Submit order form',
      label: blockName,
      request_id: requestId,
      ...getCustomDimensionsForLanding(),
      context: {
        dl_block_name: blockName,
        request_id: requestId,
        ...getCustomDimensionsForLanding()
      }
    })
  },
  'program-download-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicking on download',
      ...getCustomDimensionsForLanding()
    })
  },
  'price-form-send': (data, { provider, platform }) => {
    const { requestId, blockName, label } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on button send in price',
      label,
      platform,
      dl_block_name: blockName,
      request_id: requestId,
      ...getCustomDimensionsForLanding(),
      context: {
        platform,
        dl_block_name: blockName,
        request_id: requestId,
        ...getCustomDimensionsForLanding()
      }
    })
  },
  'price-type-click': (data, { provider }) => {
    const { action, blockName } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action,
      dl_block_name: blockName,
      label: blockName,
      ...getCustomDimensionsForLanding(),
      context: {
        dl_block_name: blockName,
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'work-want-btn-click': (data, { provider, platform }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Want to know button clicked',
      platform,
      ...getCustomDimensionsForLanding()
    })

    // TODO отрефакторить
    provider.$gtm.push({
      event: 'student_support',
      details: {
        category: 'student_support',
        action: 'click'
      }
    })
  },
  'trap-popup-show': (data, { provider, platform }) => {
    const { probability, label } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      probability,
      label,
      action: 'Pop-up shown',
      platform,
      ...getCustomDimensionsForLanding()
    })
  },
  'trap-popup-close': (data, { provider, platform }) => {
    const { probability, label } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      probability,
      label,
      action: 'Pop-up skipped',
      platform,
      ...getCustomDimensionsForLanding()
    })
  },
  'trap-popup-send': (data, { provider, platform }) => {
    const { label, requestId, probability } = data

    const analyticsData = {
      event: 'mainEvent',
      category: 'Landing',
      label,
      action: 'Pop-up form sent',
      request_id: requestId,
      platform,
      ...getCustomDimensionsForLanding()
    }

    if (probability) {
      analyticsData.probability = probability
    }

    provider.$gtm.push(analyticsData)
  },
  'page-scrolled-to': (data, { provider }) => {
    const { percent } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: percent,
      action: 'User scrolled through the page',
      ...getCustomDimensionsForLanding()
    })
  },
  'video-review-item-click': (data, { provider }) => {
    const { number } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: number,
      action: 'Student video played',
      ...getCustomDimensionsForLanding()
    })
  },
  'review-button-click': (data, { provider }) => {
    const { blockName } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: blockName,
      action: 'Clicked on the video review button',
      ...getCustomDimensionsForLanding()
    })
  },
  'review-social-click': (data, { provider }) => {
    const { socialName } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: socialName,
      action: 'Clicked on the social media buttons in the video reviews block',
      ...getCustomDimensionsForLanding()
    })
  },
  'salary-currency-change': (data, { provider }) => {
    const { label } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label,
      action: 'Wages currency click',
      ...getCustomDimensionsForLanding()
    })
  },
  'work-card-mouseenter': (data, { provider }) => {
    const { title } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'View career center cards',
      label: title,
      ...getCustomDimensionsForLanding()
    })
  },
  'work-stories-popup-button-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on the button to read stories at the center of a career',
      ...getCustomDimensionsForLanding()
    })
  },
  'work-stories-social-click': (data, { provider }) => {
    const { socialName } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on the social media button in popup story in career centre',
      label: socialName,
      ...getCustomDimensionsForLanding()
    })
  },
  'tariff-subscribe-button-click': (data, { provider }) => {
    const { tariffName, blockName } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Subscribe button clicked in tariffs block',
      label: tariffName,
      ...getCustomDimensionsForLanding(),
      dl_block_name: blockName,
      context: {
        ...getCustomDimensionsForLanding(),
        dl_block_name: blockName
      }
    })
  },
  'tariff-learn-more-button-click': (data, { provider }) => {
    const { tariffName, blockName } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Learn more button clicked in tariffs block',
      label: tariffName,
      ...getCustomDimensionsForLanding(),
      dl_block_name: blockName,
      context: {
        ...getCustomDimensionsForLanding(),
        dl_block_name: blockName
      }
    })
  },
  'cart-modal-form-send': (data, { provider }) => {
    const { label, requestId, blockName } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Tariffs form sent',
      label,
      request_id: requestId,
      dl_block_name: blockName,
      ...getCustomDimensionsForLanding(),
      context: {
        request_id: requestId,
        dl_block_name: blockName,
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'footer-promotion-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Terms of the promotion',
      ...getCustomDimensionsForLanding()
    })
  },
  'vk-auth': (data, { provider }) => {
    const { userId } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'VK auth',
      id: 3144098,
      userId,
      ...getCustomDimensionsForLanding()
    })
  },
  'vk-connect-float-popup-init': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'VK connect initialized',
      ...getCustomDimensionsForLanding()
    })
  },
  'vk-connect-authorization': (data, { provider }) => {
    const { isAuth } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Authorisation',
      label: isAuth ? 'Yes' : 'No',
      ...getCustomDimensionsForLanding()
    })
  },
  'vk-connect-float-popup-login': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Log in to vk connect',
      ...getCustomDimensionsForLanding()
    })
  },
  'vk-connect-float-popup-close': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Skip vk connect',
      ...getCustomDimensionsForLanding()
    })
  },
  'vk-connect-button-click': (data, { provider }) => {
    const { blockName } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Log in with VK ID',
      dl_block_name: blockName,
      ...getCustomDimensionsForLanding()
    })
  },
  'vk-connect-form-complete': (data, { provider }) => {
    const { completeData } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Automatic form complete',
      label: completeData,
      ...getCustomDimensionsForLanding()
    })
  },
  'vk-id-authorization': (data, { provider }) => {
    const { vkUserId } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'VK ID authorization',
      label: vkUserId ? 'Yes' : 'No',
      context: {
        vk_user_id: vkUserId,
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'process-watch-video': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Watch the video',
      ...getCustomDimensionsForLanding()
    })
  },
  'process-slide-watch-video': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Click to view the video',
      ...getCustomDimensionsForLanding()
    })
  },
  'video-watched-90-percent': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: '90%',
      action: 'Part of the video viewed',
      ...getCustomDimensionsForLanding()
    })
  },
  'details-modal-zoom-change': (data, { provider }) => {
    const { blockName } = data
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on zoom change',
      dl_block_name: blockName,
      ...getCustomDimensionsForLanding(),
      context: {
        dl_block_name: blockName,
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'portfolio-card-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on the participant\'s work card',
      ...getCustomDimensionsForLanding(),
      context: {
        ...getCustomDimensionsForLanding()
      }
    })
  },
  'portfolio-popup-picture-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on the image in the participant\'s work card',
      ...getCustomDimensionsForLanding()
    })
  },
  'portfolio-popup-next-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Arrow button clicked',
      ...getCustomDimensionsForLanding()
    })
  },
  'details-popup-zoom-change': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on zoom change',
      ...getCustomDimensionsForLanding()
    })
  },
  'mentor-shown-card': (data, { provider }) => {
    const { id } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: id,
      action: 'Support team card shown',
      ...getCustomDimensionsForLanding()
    })
  },
  'mentor-click-details': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Feedback examples selected',
      ...getCustomDimensionsForLanding()
    })
  },
  'mentor-click-card': (data, { provider }) => {
    const { id } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: id,
      action: 'Support team card clicked',
      ...getCustomDimensionsForLanding()
    })
  },
  'mentor-close-modal': (data, { provider }) => {
    const { id } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: id,
      action: 'Support team slides closed',
      ...getCustomDimensionsForLanding()
    })
  },
  'mentor-stop-slider': (data, { provider }) => {
    const { id } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: id,
      action: 'Support team slide click',
      ...getCustomDimensionsForLanding()
    })
  },
  'resume-click-instrument': (data, { provider }) => {
    const { instrument } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: instrument,
      action: 'Clicked on tools',
      ...getCustomDimensionsForLanding()
    })
  },
  'resume-click-certificate': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked certificate example',
      ...getCustomDimensionsForLanding()
    })
  },
  'teachers-card-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Additional information about the speaker',
      ...getCustomDimensionsForLanding()
    })
  },
  'teachers-works-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Speaker\'s works',
      ...getCustomDimensionsForLanding()
    })
  },
  'all-block-consult-button-click': (data, { provider }) => {
    const { blockName, buttonText } = data;
    let { buttonType } = data;

    if (buttonType.includes('-modal')) {
      buttonType = 'popup'
    }

    const map = {
      popup: 'Clicked sign up button',
      scroll: 'Clicked on scroll to price button',
      redirect: 'Clicked on redirect button',
    }

    const action = map[buttonType]

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action,
      label: blockName,
      dl_block_name: blockName,
      buttonText: buttonText.replace(/\n/g, '').trim(),
      ...getCustomDimensionsForLanding(),
      context: {
        dl_block_name: blockName,
        buttonText: buttonText.replace(/\n/g, '').trim(),
        ...getCustomDimensionsForLanding()
      }
    })
  },
  'info-submit': (data, { provider }) => {
    const { blockName } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Pop-up form sent in the info block',
      label: blockName,
      ...getCustomDimensionsForLanding()
    })
  },
  'soundcloud-item-click': (data, { provider }) => {
    const { number } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: number,
      action: 'Click on the audio',
      ...getCustomDimensionsForLanding()
    })
  },
  'header-burger-click': (data, { provider }) => {
    const { label } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label,
      action: 'Menu button clicked',
      ...getCustomDimensionsForLanding()
    })
  },
  'header-link-click': (data, { provider }) => {
    const { label } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label,
      action: 'Item about skillbox selected',
      ...getCustomDimensionsForLanding()
    })
  },
  'referral-viewed': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Alchemere - Landing',
      action: 'Referral block view',
      ...getCustomDimensionsForLanding(),
      context: {
        ...getCustomDimensionsForLanding()
      }
    })
  },
  'referral-button-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Alchemere - Landing',
      action: 'Click on Recommend in referral block',
      ...getCustomDimensionsForLanding(),
      context: {
        ...getCustomDimensionsForLanding()
      }
    })
  },
  'referral-prompt-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Alchemere - Landing',
      action: 'Click on Prompt in referral block',
      ...getCustomDimensionsForLanding(),
      context: {
        ...getCustomDimensionsForLanding()
      }
    })
  },
  'thankyou-viewed': (data, { provider }) => {
    const { label } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Alchemere - Landing',
      label,
      action: 'Viewed thankyou page',
      ...getCustomDimensionsForLanding()
    })
  },
  'thankyou-closed': (data, { provider }) => {
    const { label } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Alchemere - Landing',
      label,
      action: 'Closed thankyou page',
      ...getCustomDimensionsForLanding()
    })
  },
  'alchemere-button-fill-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Alchemere - Landing',
      action: 'Fill form button clicked',
      ...getCustomDimensionsForLanding()
    })
  },
  'alchemere-social-click': (data, { provider }) => {
    const { label } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Alchemere - Landing',
      label,
      action: 'Clicked on the social media buttons',
      ...getCustomDimensionsForLanding()
    })
  },
  'learning-profit-slider-change': (data, { provider }) => {
    const { value } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label: value,
      action: 'Wages selected',
      ...getCustomDimensionsForLanding()
    })
  },
  'all-block-learn-more-click': (data, { provider }) => {
    const { blockName } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Learn more button clicked',
      label: blockName,
      dl_block_name: blockName,
      ...getCustomDimensionsForLanding(),
      context: {
        dl_block_name: blockName,
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'universal-certificate-button-click': (data, { provider }) => {
    const { blockName } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on view certificate in universal block',
      label: blockName,
      dl_block_name: blockName,
      ...getCustomDimensionsForLanding(),
      context: {
        dl_block_name: blockName,
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'all-block-link-in-description-click': (data, { provider }) => {
    const { blockName } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on link in description',
      label: blockName,
      dl_block_name: blockName,
      ...getCustomDimensionsForLanding(),
      context: {
        dl_block_name: blockName,
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'autopayment-modal-type-change': (data, { provider }) => {
    const { label, blockName } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Select payment method in autopay modal',
      label,
      dl_block_name: blockName,
      ...getCustomDimensionsForLanding(),
      context: {
        dl_block_name: blockName,
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'autopayment-modal-type-submit': (data, { provider }) => {
    const { label, blockName, requestId } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on pay button in simple autopay popup',
      label,
      request_id: requestId,
      dl_block_name: blockName,
      ...getCustomDimensionsForLanding(),
      context: {
        request_id: requestId,
        dl_block_name: blockName,
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'work-return-link-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on refund terms link in work',
      ...getCustomDimensionsForLanding(),
      context: {
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'comments-show-all-comments-button-click': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      action: 'Clicked on view more comments button',
      ...getCustomDimensionsForLanding(),
      context: {
        ...getCustomDimensionsForLanding(),
      }
    })
  },
  'smartpopup-trial-submit': (data, { provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Smart Popups - Landgen',
      action: 'Smartpopup trial form submit',
      ...getCustomDimensionsForLanding()
    })
  },
  'smartpopup-trial-show': (data, { provider }) => {
    const { probability } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Smart Popups - Landgen',
      action: 'Smartpopup trial was shown',
      probability,
      ...getCustomDimensionsForLanding()
    })
  },
  'smartpopup-trial-close': (data, { provider }) => {
    const { probability } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Smart Popups - Landgen',
      action: 'Smartpopup trial was closed',
      probability,
      ...getCustomDimensionsForLanding()
    })
  },
  // Пуши в $flocktory
  'fl-show-form': (data, { provider }) => {
    provider.$flocktory.push(['addToCart', {
      item: {
        // eslint-disable-next-line no-undef
        id: landingInfo && landingInfo.landing_ID,
        // eslint-disable-next-line no-undef
        price: autopayment && autopayment.price,
        count: 1,
        categoryId: 1
      }
    }])
  },
  'fl-order': (data, { provider }) => {
    const { name, email, requestId } = data

    provider.$flocktory.push(['postcheckout', {
      user: {
        name,
        email
      },
      order: {
        id: requestId,
        // eslint-disable-next-line no-undef
        price: autopayment && autopayment.price,
      }
    }]);
  },
  'up-sale-click': (data, { provider }) => {
    const { label, type } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'Landing',
      label,
      action: `Clicked on choosing ${type}`,
      ...getCustomDimensionsForLanding()
    })
  },
  'growthbook-experiment-start': (data, { provider }) => {
    const { name, variationId, userId } = data;

    provider.$gtm.push({
      event: 'mainEvent',
      category: 'GrowthBook',
      action: 'Experiment viewed',
      label: `${name}:${variationId}`,
      experiment_id: name,
      variation_id: variationId,
      id: userId,
      context: {
        experiment_id: name,
        variation_id: variationId,
        id: userId
      },
      ...getCustomDimensionsForLanding()
    })
  }
}
