export const pushDSPCounter = (value) => {
  if (window.DSPCounter && window.isNeedPushDSPCounter) {
    window.DSPCounter('send', {
      'sid'       : '222783',
      'site_area' : value,
      'user_id'   : '',
      'lead_id'   : '',
      'order_sum' : ''
    });
  }
};
