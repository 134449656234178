export const scrollTo = (element, callback) => {
  const onScroll = function () {
    // element.getBoundingClientRect().top это положение относительно вьюпорта, соответственно если 0, то значит скролл закончился
    // < 1 так как скролл может быть числом с запятой
    if (element.getBoundingClientRect().top < 1) {
      window.removeEventListener('scroll', onScroll);
      callback();
    }
  }

  window.addEventListener('scroll', onScroll);
  onScroll();

  window.scrollBy({
    top: element.getBoundingClientRect().top
  });

  // на всякий случай чтобы если что-то идет не так, слушатели удалялись
  setTimeout(() => {
    window.removeEventListener('scroll', onScroll);
  }, 10000)
};
