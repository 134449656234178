import { clickButtonGtmTag, initPagePush } from "../libs/gtm";

export const gtmAnalyticHandlersInit = () => {
  initPagePush()

  const page = document.querySelector('body');

  page.addEventListener('click', (evt) => {
    const isButton = Boolean(evt.target.closest('.ui-button'));

    isButton && clickButtonGtmTag('enroll');
  });
};
