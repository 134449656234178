import CookiesBanner from '@skillbox/cookies-banner';
import {translate} from '../utils/translations';

export const cookiesBannerInit = () => {
  const isLerna = typeof isLernaHost !== 'undefined' && isLernaHost; // eslint-disable-line
  const isGb = typeof isGbHost !== 'undefined' && isGbHost; // eslint-disable-line

  if (isGb || isLerna) {
    return
  }

  const linkToPrivacy = typeof linkToPrivacyPolicy !== 'undefined' && linkToPrivacyPolicy; // eslint-disable-line

  new CookiesBanner(linkToPrivacy, translate('cookie')).init();
};
