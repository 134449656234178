import { UiLoaderAnimationVanilla } from '@skillbox/front-box/dist/Loaders/UiLoaderAnimation/UiLoaderAnimationVanilla'

export const createAnketologTemplate = () => {
  return (
    `<section class="form-popup anketolog-survey" data-theme="light">
      <div class="form-popup__wrapper anketolog-survey__wrapper">
        <button
          class="form-popup__close anketolog-survey__close-button"
          type="button"
          aria-label="Закрыть попап"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="m17.93 19.5-6.41-6.2-6.42 6.2L4 18.43l6.42-6.18L4 6.06 5.1 5l6.42 6.19L17.93 5l1.1 1.06-6.41 6.19 6.41 6.18-1.1 1.07Z" fill="currentColor"/>
          </svg>
        </button>
            
        <div class="form-popup__content anketolog-survey__content"></div>
      </div>
    </section>`
  )
};

export const createLoaderTemplate = () => UiLoaderAnimationVanilla({ classes: 'form-popup__loader anketolog-survey__loader' });

export const createPollTemplate = (src) => {
  return `<iframe
      src="${src}"
      frameborder="0"
      class="anketolog-survey__iframe"
    />`
};
