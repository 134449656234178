export const accordionInit = () => {
  const details = document.querySelectorAll('[data-accordion]');

  details.forEach(detail => {
    detail.addEventListener('click', evt => {
      const target = evt.target.closest('summary');

      if (target) {
        const parent = target.parentElement;

        if (!parent.open) {
          const gtmAttribute = detail.dataset.gtm;
          const itemText = target.innerText;

          if (gtmAttribute && itemText) {
            const eventName = `${gtmAttribute}-accordion-click`;
            window.$analytics && window.$analytics.push(eventName, { text: itemText });
          }
        }
      }
    });
  });
};
