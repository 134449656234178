import './style.scss';
import { Timer } from '../../../js/components/Timer/Timer';
import { saleEndDate } from '../../../js/components/SaleEndDate/SaleEndDate'
import { translate } from '../../../js/utils/translations';

export default !function () {
  const block = document.querySelector('.price-v9');

  if (!block) {
    return;
  }

  const blockName = block.dataset.blockName || 'Блок без названия'
  const autopaymentTypes = block.querySelector('.price-v9__types');
  const form = block.querySelector('.form');

  const ButtonText = {
    pay: translate('form.button.pay'),
    consult: translate('form.button.consultation')
  };

  // analytics
  form?.addEventListener('form-sended', (evt) => {
    window?.$analytics.push('price-form-send', {
      requestId: evt.sendingResult?.formData.get('request_id'),
      label: evt.sendingResult?.formData.get('submit_type') || evt.sendingResult?.formData.get('payment_type'),
      blockName
    });
  });

  // init timer
  const timerWrapper = block.querySelector('.price-v9__timer-block')

  if (timerWrapper) {
    saleEndDate
      .addObserver(time => new Timer({ time, timerWrapper }))
  }

  const buttonText = block.querySelector('.price-v9__button .ui-button__text');

  if (autopaymentTypes) {
    const pushAutopaymentAnalytics = (type) => {
      window.$analytics?.push('price-type-click', {
        action: `Select ${type}`,
        blockName
      });
    };

    autopaymentTypes.addEventListener('change', (evt) => {
      const chosenType = evt.target.value;

      buttonText.textContent = ButtonText[chosenType]
        ? ButtonText[chosenType]
        : ButtonText.consult;

      pushAutopaymentAnalytics(chosenType);
    });
  }

  /* b2b */
  const b2b = block.querySelector('.ui-checkbox-field__input');

  if (b2b) {
    const companyInput = block.querySelector('[data-field="b2b"]');
    const companyField = companyInput.closest('.form__input--hidden');

    setTimeout(() => {
      if (b2b.checked) {
        companyField.classList.add('form__input--show');
      }
    }, 100)

    b2b.addEventListener('change', () => {
      companyField.classList.toggle('form__input--show');
      companyInput.disabled = !b2b.checked;
    });
  }
  /* end b2b */
}();
