class SaleEndDate {
  constructor() {
    this._endDate = null
    this._observers = new Set()
    window.$saleEndDate = this

    this.fetchSaleEndDate()

    return this
  }

  get endDate() {
    return this._endDate
  }

  set endDate(endDate) {
    this._endDate = endDate
    this._notify(endDate)
  }

  subscribe(callback) {
    this.addObserver(callback)
  }

  setToStorage(time) {
    typeof sessionStorage !== 'undefined' && sessionStorage.setItem('saleEndDate', time)
  }

  getFromStorage() {
    return typeof sessionStorage !== 'undefined' && sessionStorage.getItem('saleEndTime')
  }

  async fetchSaleEndDate() {
    const saleEndTime = this.getFromStorage()

    if (saleEndTime && (new Date(saleEndTime) - new Date()) > 0) {
      this.endDate = saleEndTime

      return
    }

    try {
      // eslint-disable-next-line
      const response = await fetch(`/landgen/api/v1/landing/${landingInfo.landing_ID}/sale-end-date/`)

      if (!response.ok) {
        await Promise.reject(response)
      }

      const { data } = await response.json()
      const time = data.sale_end_date.replace(/-/g, '/')

      this.endDate = time
      this.setToStorage(time)
    } catch (e) {
      e.message = `[SaleEndDate] ${e.message}`
      window.Sentry?.captureException(e)

      this.endDate = Date.now()
    }
  }

  addObserver(observer) {
    if (this.endDate) {
      observer(this.endDate)
      return
    }

    this._observers.add(observer)
  }

  removeObserver(observer) {
    this._observers.delete(observer)
  }

  _notify(payload) {
    this._observers.forEach((observer) => observer(payload))
  }
}

export const saleEndDate = typeof window.$saleEndDate === 'object'
  ? window.$saleEndDate
  : new SaleEndDate()
