import './style.scss';
import '../../universal-notice/v1/scripts';
import UiModal from '../../../js/components/UiModal/UiModal';
import { scrollTo } from "../../../js/components/scrollToWithCallback";
import { deactivateFocusTrap } from '../../../js/utils/focus-trap';

const pushAnalytics = (link) => {
  const label = link.dataset.analytics;

  if (label) {
    window.$analytics && window.$analytics.push('header-link-click', { label });
  }
}

const scrollHandler = (link) => {
  const selector = link.getAttribute('href');
  const elementScroll = selector && document.querySelector(selector);

  if (!elementScroll) {
    return;
  }

  const namedElement = elementScroll.closest('[data-block-name]');

  window.programlyScrollingTo = (namedElement && namedElement.dataset.blockName) || 'not-named-block';
  scrollTo(elementScroll, () => window.programlyScrollingTo = null);

  pushAnalytics(link);
}

class MobileMenu extends UiModal {
  beforeMounted() {
    const media = window.matchMedia('(min-width: 1280px)');

    const mediaChangeListener = () => {
      if (media.matches) {
        this.close();
      }
    };

    media.addEventListener('change', mediaChangeListener);

    const clickHandler = (evt, link) => {
      evt.preventDefault();
      deactivateFocusTrap(this.contentWrapper); // Иначе скролл работает неправильно
      this.close();
      scrollHandler(link);
    }

    this.contentWrapper.addEventListener('click', (evt) => {
      const closestLink = evt.target.closest('.header__nav-link')

      if (closestLink) {
        clickHandler(evt, closestLink)
      }

      const closestButton = evt.target.closest('.header__nav-order')

      if (closestButton) {
        clickHandler(evt, closestButton)
      }

      const closeButton = evt.target.closest('.ui-modal__close');

      if (closeButton) {
        window.$analytics && window.$analytics.push('header-burger-click', {
          label: 'Closed'
        });
      }
    })

    setTimeout(() => {
      this._element.querySelector('.header-mobile-nav').classList.add('header-mobile-nav--active');
    })
  }

  beforeUnmounted() {
    this._element.querySelector('.header-mobile-nav').classList.remove('header-mobile-nav--active');
  }
}

export default !function () {
  const header = document.querySelector('.header');
  const mobileMenuTemplate = header.querySelector('#mobile-menu')
  const mobileMenu = mobileMenuTemplate && mobileMenuTemplate.content.querySelector('.header__nav');

  if (!mobileMenu) {
    return
  }

  header.addEventListener('click', (evt) => {
    if (evt.target.closest('.header__nav-btn')) {
      new MobileMenu(mobileMenu, {
        template: 'clear',
        wrapperClass: 'header-mobile-nav',
        scrollingBlockClass: '.header__nav-list'
      }).init();

      window.$analytics && window.$analytics.push('header-burger-click', {
        label: 'Opened'
      });
    }

    const closestLink = evt.target.closest('.header__nav-link');

    if (closestLink) {
      evt.preventDefault();
      scrollHandler(closestLink);
    }
  })

  // Fixed header
  let scrollPos = 0;

  const setStickyHeader = () => {
    const headerHeight = header.offsetHeight;
    const isScrollRevert = scrollY < scrollPos;


    if (isScrollRevert && scrollY > headerHeight) {
      header.style.animation = '400ms ease-in-out showHeader';
      header.classList.add('header--sticky');
    } else if (scrollY > headerHeight) {
      header.style.animation = '400ms ease-in-out hideHeader';

      setTimeout(() => {
        header.classList.remove('header--sticky');
      }, 400)
    }

    scrollPos = scrollY;
  }

  document.addEventListener('scroll', setStickyHeader);
}();
