import { getUiPictureTemplate } from '@skillbox/front-box/dist/Media/UiPicture/UiPictureTemplate'
import { getUiSocialButtonTemplate } from '@skillbox/front-box/dist/Buttons/UiSocialButton/UiSocialButtonTemplate'
import { createElement } from '../../utils/render'
import { getExtraParams } from '../../utils/utm'
import { SuccessHTTPStatusRange } from '../../Form/const'
import UiModal from '../UiModal/UiModal'
import { data } from './data'

const getAlchemereModalTemplate = ({ images, title, description, gifts, messengers }) => {
  return (
    `<div class="alchemere-modal__wrapper ui-typography-group">
      ${getUiPictureTemplate({
        imagesData: images,
        className: 'alchemere-modal__pic',
        width: 280,
        height: 160,
        decoding: 'async',
        alt: 'Подарки'
      })}

      <div class="alchemere-modal__content">
        <h3 class="alchemere-modal__title h h--4">
          ${title}
        </h3>

        <p class="alchemere-modal__desc p p--1">
          ${description}
        </p>

        <ul class="alchemere-modal__list alchemere-gifts">
          ${gifts.map((gift) => (
            `<li class="alchemere-gifts__item">
              <img
                class="alchemere-gifts__icon"
                width="32"
                height="32"
                src="${gift.icon}"
                alt=""
              >

              <h4 class="alchemere-gifts__subtitle t t--3">
                ${gift.title}
              </h4>

              <p class="alchemere-gifts__text p p--3">
                ${gift.description}
              </p>
            </li>`
          )).join('')}
        </ul>

        ${messengers.length
          ? `<ul class="alchemere-messengers">
            ${messengers.map((messenger) => (
              `<li class="alchemere-messengers__item">
                ${getUiSocialButtonTemplate({
                  type: messenger.type,
                  title: messenger.title,
                  href: messenger.href,
                  size: 'big',
                  classes: 'alchemere-messengers__button',
                  attrs: `data-messenger="${messenger.type}"`
                })}
              </li>`
            )).join('')}
          </ul>`
          : ''
        }
      </div>
    </div>`
  )
}

// мутируем мессенджеры, добавляя в ссылку все динамические параметры
const getMutatedModalData = async (data) => {
  const { phone, email } = window.$lastSendedFormData
  const skbClientId = window.clientIds.getSkbId()
  const gaClientId = await window.clientIds.getGaId()
  const sessionExternalId = skbClientId?.replace(/-/g, '')
  const params = getExtraParams() ?? ''

  return {
    ...data,
    messengers: data.messengers.map((messenger) => {
      const union = new URL(messenger.href).search ? '&' : '?';
      messenger.href = `${messenger.href}${union}${params}&phone=${phone}&email=${email}&skb_client_id=${skbClientId}&ga_client_id=${gaClientId}&session_external_id=${sessionExternalId}`;
      return {
        ...messenger,
        link: messenger.link
      }
    })
  }
}

export class AlchemereLernaThanksModal extends UiModal {
  constructor(data) {
    const template = createElement(getAlchemereModalTemplate(data))

    super(template, {
      wrapperClass: 'alchemere-modal',
      isCenter: true
    })
  }

  beforeMounted() {
    this._element.addEventListener('click', this.handleMessengersClick)
  }

  beforeUnmounted() {
    this._element.removeEventListener('click', this.handleMessengersClick)
  }

  handleMessengersClick = (evt) => {
    const target = evt.target.closest('.alchemere-messengers__button')
    if (!target) {
      return
    }

    const formData = new FormData()
    formData.set('request_id', window.$lastSendedFormData.requestId)
    formData.set('messenger', target.dataset.messenger)

    fetch('/landgen/api/v1/landings/proxy/gateway/thank-you/', {
      method: 'POST',
      body: formData,
      headers: { 'Accept': 'application/json' }
    })
      .then((response) => {
        if (
          response.status < SuccessHTTPStatusRange.MIN ||
          response.status > SuccessHTTPStatusRange.MAX
        ) {
          throw new Error(`${response.status}: ${response.statusText}`)
        }
      })
      .catch((e) => {
        e.message = `[Lerna thanks modal]: ${e.message}`
        window.Sentry.captureException(e)
      })
  }
}

export const createAlchemereLernaThanksModal = async () => {
  const templateData = await getMutatedModalData(data.lerna)

  return new AlchemereLernaThanksModal(templateData)
}
