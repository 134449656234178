import { Media } from '../utils/const';
import UiModal from "./UiModal/UiModal";

const DEFAULT_POSITIONS = {
  vertical: 'top',
  horizontal: 'center'
};

const mediaMobile = window.matchMedia(`(max-width: ${Media.SM_MAX}px)`);
const mediaTablet = window.matchMedia(`(max-width: ${Media.MD_MAX}px)`);

const tooltips = document.querySelectorAll('.ui-tooltip');

const removePosition = (tooltipPopup, tooltipContent) => {
  tooltipPopup.classList.remove('ui-tooltip-popover--top');
  tooltipPopup.classList.remove('ui-tooltip-popover--bottom');
  tooltipContent.classList.remove('ui-tooltip-content__wrapper--center');
  tooltipContent.classList.remove('ui-tooltip-content__wrapper--left');
  tooltipContent.classList.remove('ui-tooltip-content__wrapper--right');
}

const setPosition = (tooltip, tooltipButton, tooltipPopup, isInit = false) => {
  if (isInit || tooltipButton.matches(':hover') || tooltipButton.matches(':focus')) {
    const { verticalPosition, horizontalPosition } = calcPosition(tooltip, tooltipPopup, tooltipButton);

    const isPositions = verticalPosition && horizontalPosition;

    tooltip.dataset.isChangePosition = isPositions && (verticalPosition !== tooltip.dataset.verticalPosition || horizontalPosition !== tooltip.dataset.horizontalPosition);

    if (tooltip.dataset.isChangePosition) {
      tooltipPopup.classList.add('ui-tooltip-popover--opacity-animation');
      // opacity-animation - при смене позиции в анимации остается только opacity, иначе position тултип прилетает неожиданно
      tooltip.dataset.verticalPosition = verticalPosition;
      tooltip.dataset.horizontalPosition = horizontalPosition;

      const tooltipContent = tooltipPopup.querySelector('.ui-tooltip-content__wrapper');
      removePosition(tooltipPopup, tooltipContent);

      tooltipPopup.classList.add(`ui-tooltip-popover--${verticalPosition}`);
      tooltipContent.classList.add(`ui-tooltip-content__wrapper--${horizontalPosition}`);

      setTimeout(() => {
        tooltipPopup.classList.remove('ui-tooltip-popover--opacity-animation');
      }, 300);
    }
  }
}

const calcPosition = (tooltip, tooltipPopup, tooltipButton) => {
  const MARGIN_TO_TOOLTIP = 19;
  const popover = tooltipPopup && tooltipPopup.querySelector('.ui-tooltip-content__wrapper--desktop');
  const triggerBtn = tooltipButton;

  if (!popover || !triggerBtn) {
    return {
      verticalPosition: null,
      horizontalPosition: null
    }
  }

  let verticalPosition = tooltip.dataset.verticalPosition;
  let horizontalPosition = tooltip.dataset.horizontalPosition;

  if (popover && triggerBtn) {
    const tooltipRect = popover.getBoundingClientRect();
    const triggerRect = triggerBtn.getBoundingClientRect();

    if (triggerRect.y - tooltipRect.height - MARGIN_TO_TOOLTIP > 0) {
      verticalPosition = 'top';
    } else {
      verticalPosition = 'bottom';
    }

    const halfTriggerX = triggerRect.x + (triggerRect.width / 2);
    const bodyWidth = document.body.clientWidth;

    if (halfTriggerX - tooltipRect.width / 2 > 0 && halfTriggerX + tooltipRect.width / 2 < bodyWidth) {
      horizontalPosition = 'center';
    } else if (halfTriggerX + tooltipRect.width / 2 > bodyWidth) {
      horizontalPosition = 'right';
    } else if (halfTriggerX - tooltipRect.width / 2 < 0) {
      horizontalPosition = 'left';
    }
  }

  return { verticalPosition, horizontalPosition };
}

const prepareUiModalTemplate = (tooltip) => {
  const tooltipModalTemplate = tooltip.querySelector('.ui-tooltip-content__content').cloneNode(true);
  tooltipModalTemplate.querySelector('.ui-tooltip-popover__button')?.remove()

  const customCloseButton = document.createElement('button');
  customCloseButton.classList.add('ui-tooltip-modal__button', 'ui-button', 'ui-button--filled-main', 'ui-button--medium', 'ui-button--stretch')
  customCloseButton.innerText = 'Понятно'
  tooltipModalTemplate.appendChild(customCloseButton)

  return { tooltipModalTemplate, customCloseButton }
}


export const setTooltipHandler = (tooltip) => {
  let tooltipModal = null;
  const tooltipButton = tooltip.querySelector('.ui-tooltip__button');
  const tooltipPopup = tooltip.querySelector('.ui-tooltip-popover');
  const closePopoverTabletButton = tooltipPopup.querySelector('.ui-tooltip-popover__button');
  const { tooltipModalTemplate, customCloseButton } = prepareUiModalTemplate(tooltip)

  tooltip.dataset.verticalPosition = DEFAULT_POSITIONS.vertical;
  tooltip.dataset.horizontalPosition = DEFAULT_POSITIONS.horizontal;
  tooltip.dataset.isChangePosition = false;
  tooltipPopup.classList.add('ui-tooltip-popover--opacity-animation');


  if (!tooltipPopup) {
    return;
  }

  tooltip.addEventListener('click', (e) => {
    e.stopPropagation();
  });

  const setNotMobilePosition = () => {
    !mediaMobile.matches && setPosition(tooltip, tooltipButton, tooltipPopup, true);
  }

  setNotMobilePosition();

  const tooltipHandler = () => {
    setPosition(tooltip, tooltipButton, tooltipPopup);
  }

  const closePopoverTablet = () => {
    tooltipPopup.classList.remove('ui-tooltip-popover--top-active', 'ui-tooltip-popover--bottom-active');
    document.body.removeEventListener('click', closePopoverTablet);
    closePopoverTabletButton && closePopoverTabletButton.removeEventListener('click', closePopoverTablet);
  }

  const openPopoverTablet = () => {
    setPosition(tooltip, tooltipButton, tooltipPopup);

    const tabletAnimateClass = tooltip.dataset.verticalPosition === 'top'
      ? 'ui-tooltip-popover--top-active'
      : 'ui-tooltip-popover--bottom-active';

    tooltipPopup.classList.add(tabletAnimateClass);
    closePopoverTabletButton && closePopoverTabletButton.addEventListener('click', closePopoverTablet);
    document.body.addEventListener('click', closePopoverTablet);
  }

  const tooltipTabletHandler = () => {
    const isPopoverOpened = tooltipPopup.classList.contains('ui-tooltip-popover--top-active') || tooltipPopup.classList.contains('ui-tooltip-popover--bottom-active');

    isPopoverOpened
      ? closePopoverTablet()
      : openPopoverTablet();
  }

  const showTooltipPopup = () => {
    tooltipModal = new UiModal(tooltipModalTemplate, {
      wrapperClass: 'ui-tooltip-modal',
      isCloseButton: false,
    });

    tooltipModal.init();
    customCloseButton.addEventListener('click', tooltipModal.close)
  }

  const mediaChangeListener = () => {
    tooltipButton.removeEventListener('click', showTooltipPopup);
    tooltipButton.removeEventListener('focus', tooltipHandler);

    tooltip.removeEventListener('mouseenter', tooltipHandler);
    document.body.removeEventListener('click', closePopoverTablet);

    closePopoverTablet()

    tooltipModal && tooltipModal.close();
    tooltipModal = null;

    if (mediaMobile.matches) {
      tooltipButton.addEventListener('click', showTooltipPopup);
    } else if (mediaTablet.matches) {
      tooltipButton.addEventListener('click', tooltipTabletHandler);
    } else {
      tooltip.addEventListener('mouseenter', tooltipHandler);
      tooltipButton.addEventListener('focus', tooltipHandler);
    }

    if (mediaMobile.matches) {
      window.removeEventListener('resize', setNotMobilePosition)
    } else {
      setNotMobilePosition()
      window.addEventListener('resize', setNotMobilePosition);
    }
  }

  mediaChangeListener();
  mediaTablet.addEventListener('change', mediaChangeListener);
  mediaMobile.addEventListener('change', mediaChangeListener);
};

export const tooltipsInit = () => {
  tooltips.forEach(setTooltipHandler);
};
