import { AbTesting } from '@skillbox/ab-testing'

/*eslint-disable */
export const abInit = () => {
  if (typeof abExperiments === 'undefined' || Object.keys(abExperiments).length === '0' || typeof environment === 'undefined') {
    return
  }

  const clientId = window.clientIds.getSkbId()

  const isProduction = environment === 'production'
  const projectName = 'landgen'

  const activeExperiments = []

  Object.keys(abExperiments).forEach((key) => {
    if (abExperiments[key]) {
      activeExperiments.push(abExperiments[key])
    }
  })

  window.AbTestingPlugin = new AbTesting({
    baseUrl: !isProduction && 'https://ab-api-stg.skillbox.pro/',
    errorHandler: window.Sentry?.captureException,
    projectName,
    activeExperiments
  })

  // Тут запускаем тесты
  if (abExperiments.ABExperimentRedirect) {
    window.AbTestingPlugin.startExperiment(abExperiments.ABExperimentRedirect, clientId)
      .then(({ group, slug }) => {
        const DEFAULT_GROUP = 'A'

        if (group.toLowerCase() !== DEFAULT_GROUP.toLowerCase() && (slug || !sessionStorage.getItem('isAaTestPassed'))) {
          !slug && sessionStorage.setItem('isAaTestPassed', 'true')
          window.location.href = slug ? slug + window.location.search : window.location.href
        }
      })
      .catch(() => {})
  }
};
/*eslint-enable */
