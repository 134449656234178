import { GrowthBook } from '@growthbook/growthbook'
import { getCookie } from '../../../js/utils/cookies'

const clearSmartPopupFeature = (features) => {
  if (features.smart_popup_threshold?.rules) {
    features.smart_popup_threshold.rules.forEach((rule) => {
      for (const propName in rule) {
        if (rule[propName] === null) {
          delete rule[propName];
        }
      }
    })
  }

  return features
}

const clearSmartPopupPassedFeature = (passedFeatures) => {
  if (!passedFeatures) {
    return null
  }

  delete passedFeatures.smart_popup_forbidden_utms
  delete passedFeatures.smart_popup_threshold

  return passedFeatures
}

const GrowthBookClient = class {
  constructor(features, passedFeatures) {
    this.features = clearSmartPopupFeature(features) // TODO сейчас бэк возвращает лишние поля c null, которые ломают эксперименты. Нужно доработать бэк
    this.passedFeatures = clearSmartPopupPassedFeature(passedFeatures) ?? {}
    this.userId = window.clientIds.getSkbId() ?? null

    this.instance = new GrowthBook({
      enableDevMode: false,
      features: this.features,
      attributes: {
        ...this.getAttributes()
      }
    })
  }

  getAttributes() {
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
    const queryParams = new URLSearchParams(window.location.search.toLowerCase())

    const params = utmParams.reduce((acc, param) => {
      if (queryParams.has(param)) {
        acc[param] = queryParams.get(param)
      }

      return acc
    }, {})

    return {
      ...params,
      id: this.userId,
      utm_aggregated_data: getCookie('utm_aggregated_data'),
      page_path: window.location.pathname
    }
  }

  getFeatureValue(experimentKeys) {
    const list = Array.isArray(experimentKeys) ? experimentKeys : [experimentKeys]

    return list.map((key) => {
      if (!this.passedFeatures[key]) {
        const result = this.instance.evalFeature(key)

        this.updatePassedFeatures(key, result)
      }

      return this.passedFeatures[key]
    })
  }

  updatePassedFeatures(key, result) {
    this.passedFeatures[key] = result

    result.experiment && this.trackExperiment(result.experiment.key, result.experimentResult.variationId)
  }

  trackExperiment(experimentName, variationId) {
    window.$analytics?.push('growthbook-experiment-start', {
      name: experimentName,
      variationId: variationId,
      userId: this.userId
    })
  }

  trackBackendFeatures() {
    for (const slug in this.passedFeatures) {
      const result = this.passedFeatures[slug]

      result.experiment && this.trackExperiment(result.experiment.key, result.experiment.variation_id)
    }
  }
}

export const growthbookInit = () => {
  /* eslint-disable-next-line */
  if (typeof growthbook !== 'undefined' && growthbook) {
    /* eslint-disable-next-line */
    const transformedPassedFeatures = growthbook.backendFeatures?.reduce((acc, feature) => {
      acc[feature.feature_key] = feature
      return acc
    }, {})

    /* eslint-disable-next-line */
    window.$growthbookClient = new GrowthBookClient(growthbook.allFeatures ?? {}, transformedPassedFeatures)

    try {
      /* eslint-disable-next-line */
      if (growthbook.backendFeatures) {
        window.$growthbookClient.trackBackendFeatures()
      }
    } catch (e) {
      e.message = `[Growthbook]: ${e.message}`
      console.error(e)
      window.Sentry?.captureException(e)
    }
  }
}
