import { name, phone, email } from '@skillbox/front-box/dist/libs/vee-validate/rules'

export const defaultRules = {
  name,
  phone,
  email,
  company_name: {
    required: true
  },
  accept: {
    checked: true
  }
};
