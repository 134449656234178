// подставляешь слово под эти цифры и так и записывешь [1,3,5] => ['курс', 'курса', 'курсов']
const words = {
  month: ['месяц', 'месяца', 'месяцев'],
  comment: ['отзыв', 'отзыва', 'отзывов']
}

export const getWord = (word, count) => {
  const num = count % 100
  const mod = num % 10

  if (num !== 11 && mod === 1) {
    return words[word][0]
  } else if (mod >= 2 && mod <= 4 && (num < 10 || num > 20)) {
    return words[word][1]
  } else {
    return words[word][2]
  }
}
