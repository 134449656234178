import AbstractObserver from '../../../../js/utils/abstract-observer.js';
import { UpdateType } from '../../../../js/utils/const';

export class SpeakerModel extends AbstractObserver {
  constructor() {
    super();

    this._data = {};
  }

  async loadData(id) {
    try {
      if (!this._data[id]) {
        /* eslint-disable-next-line */
        const response = await fetch(`${speakersApiUrl}/api/v1/entity/landing/resource/${landingInfo.landing_ID}/speaker/${id}/speaker_and_works`);

        if (!response.ok) {
          await Promise.reject(response)
        }

        const { data } = await response.json();

        this._data[id] = data;
      }

      this._notify(UpdateType.INIT);
    } catch (e) {
      e.message = `[speakers] ${e.message}`
      window.Sentry.captureException(e)
      this._notify(UpdateType.ERROR);
    }
  }

  getSpeaker(id) {
    return this._data[id];
  }
}
