import './style.scss'

import { saleEndDate } from '../../../js/components/SaleEndDate/SaleEndDate'
import { Timer } from '../../../js/components/Timer/Timer'
import {pushDSPCounter} from '../../../js/libs/pixel'
import { showOrderModal } from '../../popups/v1/OrderModal/scripts'

export default !function () {
  const block = document.querySelector('.start-screen-v3')

  if (!block) {
    return
  }

  const blockName = block.closest('[data-block-name]')?.dataset.blockName || 'Блок без названия'
  const button = block.querySelector('.start-screen-v3__button')

  if (button) {
    const siteArea = button.dataset.pixel

    button.addEventListener('click', (evt) => {
      evt.preventDefault()

      const buttonType = button.dataset.actionButton || (button.dataset.redirectLink && 'redirect')

      if (buttonType) {
        window.$analytics?.push('all-block-consult-button-click', {
          blockName,
          buttonType,
          buttonText: button.textContent
        })
      }

      if (button.dataset.actionButton === 'order-modal') {
        const blockName = button.closest('[data-block-name]')?.dataset.blockName ?? 'Блок без названия'
        showOrderModal({ blockName })
      }

      siteArea && pushDSPCounter(siteArea)
    })

    if (window.innerWidth < 1279) {
      const blocksWithFooter = document.querySelectorAll('.footer')
      const blocksWithButton = document.querySelectorAll('.block:not(.start-screen-v3) .ui-button')
      const blocks = [...blocksWithButton, ...blocksWithFooter]
      const bar = document.querySelector('.start-screen-v3__bar')

      let visibleCount = 1

      const changeVisibleButton = (entries) => {
        entries.forEach(entry => {
          entry.isIntersecting ? ++visibleCount : --visibleCount
          visibleCount = visibleCount > 0 ? visibleCount : 0

          visibleCount
            ? bar.classList.add('start-screen-v3__bar--hidden')
            : bar.classList.remove('start-screen-v3__bar--hidden')
        })
      }

      const observer = new IntersectionObserver(changeVisibleButton, { rootMargin: '150px' })

      blocks.forEach(block => {
        observer.observe(block)
      })
    }
  }

  // init timer
  const timers = block.querySelectorAll('[data-type=timer]')

  timers.forEach((timerWrapper) => {
    saleEndDate
      .addObserver(time => new Timer({ time, timerWrapper }))
  });
}()
