export const videoModalInit = async () => {
  const firstVideoElement = document.querySelector('.js-project-video');

  if (!firstVideoElement) {
    return;
  }

  const { UiVideoModal } = await import('./UiVideoModal');
  const { showTrailerGtmEvent } = await import('../../libs/gtm');

  const clickVideoHandler = (evt) => {
    const block = evt.target.closest('.js-project-video');

    if (!block) {
      return;
    }

    evt.preventDefault();

    const video = block.dataset.video;
    const isNeedTrackForGTM = Boolean(block.dataset.videoNeedTrack);
    const theme = block.dataset.videoView === 'vertical'
      ? 'vertical'
      : 'horizontal';
    const playerType = block.dataset.playerType;

    const options = {
      video,
      playerType,
      theme,
      isNeedTrackForGTM
    };

    new UiVideoModal(options).init();

    const gtmEvent = block.dataset.gtm;
    gtmEvent && showTrailerGtmEvent(gtmEvent);
  }

  document.addEventListener('click', clickVideoHandler);
}
