import { createElement, render, RenderPosition } from '../../../../js/utils/render'

const gradeOfType = {
  course: 'Basic',
  profession: 'Advanced'
}

const getHeaderTemplate = ({ title, description }) => (
  `<div class="up-sale-form__top ui-typography-group">
    <h3 class="up-sale-form__title h h--5">
      ${title}
    </h3>

    ${description
      ? `<p class="up-sale-form__desc p p--2">
          ${description}
        </p>`
      : ''
    }
  </div>`
)

export class UpSaleForm {
  data = {
    pay: {
      title: 'Выберите формат обучения'
    },
    consult: {
      title: 'Выберите формат обучения',
      description: 'Или дождитесь звонка менеджера&nbsp;&mdash; он&nbsp;поможет определиться'
    }
  }

  constructor(wrapper, template, { type, blockName }) {
    this.wrapper = wrapper
    this.template = template
    this.headerTmplate = null
    this.type = type
    this.blockName = blockName
    this.form = template.querySelector('.form')
  }

  render() {
    this.setFirstType()

    this.form.addEventListener('submit', this.formSubmitHandler)

    this.renderHeaderElement()
    render(this.wrapper, this.template)
  }

  destroy() {
    this.form.removeEventListener('submit', this.formSubmitHandler)

    this.removeHeaderElement()
    this.template.remove()
  }

  setFirstType() {
    const types = this.form.querySelectorAll('.up-sale-type')
    const firstInput = types[0]?.querySelector('.up-sale-type__input')

    if (firstInput) {
      firstInput.checked = true
    }
  }

  emitFormEvent(form, params, name) {
    const customEvent = new Event(name)

    customEvent.sendingResult = params
    form.dispatchEvent(customEvent)
  }

  formSubmitHandler = (evt) => {
    evt.preventDefault()

    const checkedField = this.form.querySelector('.up-sale-type__input:checked')
    const upSaleType = checkedField?.dataset.type

    // 'form-success' - так как отсюда ничего не отправляем на бэк, просто имитируем успешную отправку формы
    this.emitFormEvent(this.form, { upSaleType, formData: new FormData(this.form) }, 'form-success')

    window.$analytics?.push('up-sale-click', {
      label: `${this.blockName}|${gradeOfType[upSaleType]}`,
      type: upSaleType
    })
  }

  renderHeaderElement() {
    this.headerTmplate = createElement(getHeaderTemplate(this.data[this.type]))
    render(this.template, this.headerTmplate, RenderPosition.AFTERBEGIN)
  }

  removeHeaderElement() {
    if (this.headerTmplate === null) {
      return
    }

    this.headerTmplate.remove()
    this.headerTmplate = null
  }
}
