import Swiper, { Navigation, Pagination, EffectFade, FreeMode, Autoplay, Controller, A11y } from 'swiper';
import { pushAnalyticSlidePosition } from '../../libs/customAnalytics/eventEmiters/sliderNavigation';
import { Media } from '../../utils/const';

const matchMedia = {
  sm: window.matchMedia(`(max-width: ${Media.SM_MAX}px)`),
  md: window.matchMedia(`(min-width: ${Media.MD}px) and (max-width: ${Media.MD_MAX}px)`),
  lg: window.matchMedia(`(min-width: ${Media.LG}px) and (max-width: ${Media.LG_MAX}px)`),
  xl: window.matchMedia(`(min-width: ${Media.XL}px)`)
}

const Module = {
  navigation: Navigation,
  pagination: Pagination,
  effectFade: EffectFade,
  freeMode: FreeMode,
  autoplay: Autoplay,
  controller: Controller,
  a11y: A11y
};

const defaultSettings = {
  slidesPerView: 'auto',
  watchSlidesProgress: true,
  spaceBetween: 0,

  slideClass: 'js-slider-item',
  slideActiveClass: 'js-slider-item-active',
  wrapperClass: 'js-slider-list',

  loop: true,
  effect: 'slide',
};

/**
 * @param {Object} option
 * @param {HTMLElement} option.wrapper
 * @param {string[]} option.modules
 * @param {Object} option.settings
 * @param {Object} option.initCondition
 * @param {HTMLElement} option.controls
 * @param {function} option.createCallback
 * @param {function} option.destroyCallback
 * @param {boolean} option.isFocusable
 */
export class Slider {
  constructor({ wrapper, settings, modules = [], initCondition = null, controls = null, createCallback = null, destroyCallback = null, isFocusable = false }) {
    this._wrapper = wrapper
    this._settings = {
      ...defaultSettings,
      ...settings,
    };

    this.slider = null;
    this._modules = modules;
    this._initCondition = initCondition;
    this._controls = controls;
    this._isFocusable = isFocusable;

    this.createCallback = createCallback
    this.destroyCallback = destroyCallback

    this._smMediaChangeHandler = this._smMediaChangeHandler.bind(this);
    this._mdMediaChangeHandler = this._mdMediaChangeHandler.bind(this);
    this._lgMediaChangeHandler = this._lgMediaChangeHandler.bind(this);
    this._xlMediaChangeHandler = this._xlMediaChangeHandler.bind(this);
    this._focusSlideHandler = this._focusSlideHandler.bind(this);
  }

  init() {
    for (const mq in this._initCondition) {
      if (this._initCondition[mq] && matchMedia[mq].matches) {
        this._create();
      }

      if (!this._initCondition[mq] && matchMedia[mq].matches) {
        this._unslider();
      }

      matchMedia[mq].addEventListener('change', this[`_${mq}MediaChangeHandler`]);
    }
  }

  get modules() {
    return this._modules.map((item) => Module[item]);
  }

  _create() {
    const settings = this._getCurrentSettings();

    if (this._controls) {
      this._controls.removeAttribute('style');
      settings.loop && this._removeDisabled(this._controls)
    }

    this.slider = new Swiper(this._wrapper, {
      modules: this.modules,

      ...settings,

      on: {
        ...settings.on,

        autoplay: (swiper) => {
          swiper.isAutoplayChangeSlide = true
        },
        realIndexChange: (swiper) => {
          window.lazyLoadInstance?.update();
          !swiper.isAutoplayChangeSlide && pushAnalyticSlidePosition(swiper);

          swiper.isAutoplayChangeSlide = false

          settings?.on?.realIndexChange?.();
        }
      }
    });

    if (this._isFocusable) {
      this._setTabIndex();
      this._wrapper.addEventListener('focus', this._focusSlideHandler, true);
    }

    this._wrapper.dataset.sliderInit='true'
    this.createCallback?.(this.slider);
  }

  _unslider() {
    this._controls && (this._controls.style.display = 'none');
    this._wrapper.dataset.sliderInit='false'
    this.destroyCallback?.();
  }

  destroy() {
    if (this.slider !== null) {
      this.slider.destroy();
      this.slider = null;

      this._isFocusable && this._wrapper.removeEventListener('focus', this._focusSlideHandler, true);

      this._unslider();
    }
  }

  _rebuild(evt, mq) {
    if (evt.matches) {
      this.destroy();
      this._initCondition[mq] && this._create();
    }
  }

  _smMediaChangeHandler(evt) {
    this._rebuild(evt, 'sm');
  }

  _mdMediaChangeHandler(evt) {
    this._rebuild(evt, 'md');
  }

  _lgMediaChangeHandler(evt) {
    this._rebuild(evt, 'lg');
  }

  _xlMediaChangeHandler(evt) {
    this._rebuild(evt, 'xl');
  }

  _focusSlideHandler(evt) {
    const target = evt.target;

    if (target && target.classList.contains(this._settings.slideClass)) {
      this.slider.slideTo(this.slider.slides.indexOf(target), 0);
    }
  }

  _getCurrentSettings() {
    let settings = { ...this._settings };

    for (const mq in settings.media) {
      if (matchMedia[mq].matches) {
        settings = {
          ...settings,
          ...settings.media[mq]
        }

        break;
      }
    }

    delete settings.media

    return settings;
  }

  _removeDisabled(controls) {
    controls.querySelectorAll('button')?.forEach((btn) => {
      btn.disabled = false
    })
  }

  _setTabIndex() {
    this.slider?.slides?.forEach((item) => {
      item.tabIndex = 0;
    })
  }
}
