export const isSafariInit = () => {
  let isSafari = false

  if (window && document) {
    isSafari = /^(?:(?!chrome|android).)*safari/i.test(navigator.userAgent)
    const body = document.querySelector('body')
    const isNeedClass = body && isSafari

    isNeedClass && body.classList.add('is-safari')
  }
};
