import eventEmiters from './eventEmiters/index';
import $analytics from './customAnalytics';
import events from './events';

export const customAnalyticsInit = () => {
  /* eslint-disable-next-line */
  if (!isGbHost) {
    Object.keys(events).forEach(event => $analytics.createEvent(event, events[event]));

    window.$analytics = $analytics;

    eventEmiters();
  }
};
