// new Array(steps + 1).fill(0).map((val, index) => index / steps || 0)
const thresholdArray = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1]

const steps = [2, 4, 6, 9, 13, 19, 26, 35, 45, 60, 90, 120, 240, 360, 720]

const dataAttr = 'data-block-name'
const datasetFieldName = 'blockName'

export default () => {
  const blocks = []

  const observerCallback = ([entry]) => {
    const target = entry.target
    const blockName = target.dataset[datasetFieldName]
    const currentBlock = blocks.find(item => blockName === item.name)

    // если window.programlyScrollingTo, то не запускаем счетчик нигде кроме этого блока
    if (!window.programlyScrollingTo || window.programlyScrollingTo === currentBlock.name) {
      let requiredIntersectionRatio = 0.7

      // если блок больше высоты экрана, то расчитываем какой максимальный процент блока может быть виден, и берем 70% от этого значения
      if (target.offsetHeight > window.innerHeight) {
        requiredIntersectionRatio = window.innerHeight / target.offsetHeight * 0.7
      }

      if (!entry.isIntersecting) {
        currentBlock.showCount = currentBlock.showCount + 1
      }

      if (entry.isIntersecting && entry.intersectionRatio >= requiredIntersectionRatio) {
        if (currentBlock.interval) {
          return
        }

        currentBlock.interval = setInterval(() => {
          currentBlock.timeVisible += 1

          if (steps.includes(currentBlock.timeVisible)) {
            if(currentBlock.showCount === 0) {
              currentBlock.showCount = 1 // если загрузились на этом блоке
            }

            window.$analytics && window.$analytics.push('block-visible-timing', {
              action: currentBlock.showCount > 1 ? 'Reiterated view block page' : 'View block page',
              name: currentBlock.name,
              slug: currentBlock.slug,
              timeVisible: `${currentBlock.timeVisible}-sec`,
              showCount: currentBlock.showCount
            })
          }
        }, 1000)
      } else {
        clearInterval(currentBlock.interval)
        currentBlock.interval = null
      }

      if (currentBlock.timeVisible >= steps[steps.length - 1]) {
        currentBlock.observer.disconnect()
      }
    }
  }

  const elements = [...document.querySelectorAll(`[${dataAttr}]`)]

  elements.forEach((item, i) => {
    blocks.push({
      name: item.dataset[datasetFieldName],
      slug: item.id,
      showCount: 0,
      timeVisible: 0,
      interval: null
    })

    blocks[i].observer = new IntersectionObserver(observerCallback, { threshold: thresholdArray })

    blocks[i].observer.observe(item)
  })
}
