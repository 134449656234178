import './style.scss';
import DisclaimerModal from './DisclaimerModal/DisclaimerModal';
import { Media } from "../../../js/utils/const";

export default !function () {
  const block = document.querySelector('.disclaimer')

  if (!block) {
    return
  }

  const blockName = block.closest('[data-block-name]')?.dataset.blockName || 'Блок без названия'

  const disclaimerModalTemplate = document.querySelector('#disclaimer-modal');

  if (!disclaimerModalTemplate) {
    return;
  }

  const smMax = window.matchMedia(`(max-width: ${Media.SM_MAX}px)`);
  let disclaimerModal = null;
  let isShowedDisclaimer = false;

  // TODO использовать scrollToWithCallback.js
  const pageScrollHandler = () => {
    const scrollTop = window.scrollY
    const docHeight = document.body.getBoundingClientRect().height
    const winHeight = window.innerHeight
    const scrollPercent = scrollTop / (docHeight - winHeight)
    const scrollPercentRounded = Math.round(scrollPercent * 100)

    if (scrollPercentRounded >= 50) {
      disclaimerModal = new DisclaimerModal(blockName);
      disclaimerModal.init();
      isShowedDisclaimer = true;
      document.removeEventListener('scroll', pageScrollHandler)
    }
  };

  const tabletMediaChangeListener = () => {
    if (smMax.matches) {
      !isShowedDisclaimer && document.addEventListener('scroll', pageScrollHandler);
    } else {
      disclaimerModal && disclaimerModal.close();
      document.removeEventListener('scroll', pageScrollHandler);
    }
  }

  tabletMediaChangeListener();
  smMax.addEventListener('change', tabletMediaChangeListener);
}();
