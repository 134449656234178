export const getVkAuthModel = async (containers) => {
  if (typeof vkConnect === 'undefined' || typeof vkConnectId === 'undefined') {
    return null
  }

  if (!vkConnect) { // eslint-disable-line
    return null
  }

  const { VKAuth } = await import('./VKAuthModel');
  const { VKConnect } = await import('./VKConnect');
  const { VKAuthButtons } = await import('./VKAuthButtons');
  const { VK_STORAGE_KEY } = await import('../../utils/const');
  const { getCookie } = await import('../../utils/cookies');

  // При наличии этой куки не показываем попап для авторизации, все остальная логика не меняется
  const isWasAuth = !!getCookie(VK_STORAGE_KEY);

  // vkConnectId - переменная, объявленная в index.html
  const options = {
    appId: vkConnectId, // eslint-disable-line
    authAction: '/landgen/api/v1/vk-connect/exchange-token/',
    autologinAction: '/landgen/api/v2/vk-connect/exchange-token/',
    isShowFloatingAuth: !isWasAuth && window.matchMedia('(min-width: 768px)').matches,
    debug: false
  };

  const vkAuthModel = new VKAuth()

  new VKAuthButtons(containers, vkAuthModel).init();
  new VKConnect(options, vkAuthModel).init();

  return vkAuthModel
}
