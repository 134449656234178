import {load} from 'recaptcha-v3';
import {FormType} from './const';

let recaptcha = null;

export const getToken = async () => {
  /* eslint-disable-next-line */
  if (!siteKey) {
    return null;
  }

  try {
    if (!recaptcha) {
      /* eslint-disable-next-line */
      recaptcha = await load(siteKey);
    }
    return await recaptcha.execute('homepage');
  } catch (e) {
    throw new Error(`Recaptcha error: ${e}`);
  }
};

export const getCurrentFormTypes = (formType) => {
  return {
    isAutoPayment: formType === FormType.AUTOPAYMENT,
    isPayMethods: formType === FormType.PAY,
    isLead: formType === FormType.LEAD
  }
};

export const throwError = (message, options) => {
  const error = new Error(message);

  error.options = options;

  throw error;
};

export const setBooleanFieldData = (form, formData, fieldName) => {
  const field = form.querySelector(`[name="${fieldName}"]`);

  if (field) {
    formData.set(fieldName, field.checked ? 1 : 0);
  }
}
