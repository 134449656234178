import { UiButtonVanilla } from '@skillbox/front-box/dist/Buttons/UiButton/UiButtonVanilla'
import { getUiPictureTemplate } from '@skillbox/front-box/dist/Media/UiPicture/UiPictureTemplate'
import { createElement } from '../../utils/render'
import UiModal from '../UiModal/UiModal'

import { data } from './data'
import { Anketolog } from './Anketolog'

const getAlchemereThanksModalTemplate = ({ images, title, description, gifts, buttonText }) => {
  return (
    `<div class="alchemere-modal__wrapper ui-typography-group">
      ${getUiPictureTemplate({
        imagesData: images,
        className: 'alchemere-modal__pic',
        isCover: true,
        width: 280,
        height: 160,
        decoding: 'async',
        alt: 'Подарки'
      })}

      <div class="alchemere-modal__content">
        <h3 class="alchemere-modal__title h h--4">
          ${title}
        </h3>

        <p class="alchemere-modal__desc p p--1">
          ${description}
        </p>

        <ul class="alchemere-modal__list alchemere-gifts">
          ${gifts.map((gift) => (
            `<li class="alchemere-gifts__item">
              <img
                class="alchemere-gifts__icon"
                width="32"
                height="32"
                src="${gift.icon}"
                alt=""
              >

              <h4 class="alchemere-gifts__subtitle t t--3">
                ${gift.title}
              </h4>

              <p class="alchemere-gifts__text p p--3">
                ${gift.description}
              </p>
            </li>`
          )).join('')}
        </ul>

        ${UiButtonVanilla({
          size: 'big',
          stretchMode: 'sm-max',
          title: buttonText,
          classes: 'alchemere-modal__button'
        })}
      </div>
    </div>`
  )
}

export class AlchemereThanksModal extends UiModal {
  constructor(type, url) {
    const template = createElement(getAlchemereThanksModalTemplate(data[type]))

    super(template, {
      wrapperClass: 'alchemere-modal',
      isCenter: true
    })

    this.type = type
    this.url = url

    this.clickButtonHandler = this.clickButtonHandler.bind(this)
  }

  clickButtonHandler() {
    new Anketolog(this.url)
    window.$analytics?.push('alchemere-button-fill-click')

    this.close()
  }

  beforeClose() {
    window.$analytics?.push('thankyou-closed', { label: this.type === 'trial' ? 'Start_trial' : 'Start' })
  }

  beforeMounted() {
    this.element.querySelector('.alchemere-modal__button')
      .addEventListener('click', this.clickButtonHandler)
    window.$analytics?.push('thankyou-viewed', { label: this.type === 'trial' ? 'Start_trial' : 'Start' })
  }

  beforeUnmounted() {
    this.element.querySelector('.alchemere-modal__button')
      .removeEventListener('click', this.clickButtonHandler)
  }
}
