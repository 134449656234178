import './style.scss';
import { Slider } from '../../../js/components/Slider/Slider';

export default !function () {
  const block = document.querySelector('.rating-v1');

  if (!block) {
    return;
  }

  const sliderContainer = block.querySelector('.rating-v1__slider');
  const sliderWrapper = sliderContainer.querySelector('.rating-v1__slider-wrapper');
  const slides = sliderContainer.querySelectorAll('.rating-v1__review');
  const controls = sliderContainer.querySelector('.rating-v1__controls');
  const isInit = slides.length > 1;

  const options = {
    wrapper: sliderWrapper,
    settings: {
      spaceBetween: 100,
      slidesPerView: 1,
      loop: true,

      effect: 'slide',
      slideClass: 'rating-v1__review',
      wrapperClass: 'rating-v1__list',

      navigation: {
        nextEl: '.button-arrow--next',
        prevEl: '.button-arrow--prev'
      },

      speed: 800,
      autoplay: {
        delay: 1200,
        disableOnInteraction: false
      }
    },
    modules: ['pagination', 'navigation', 'autoplay'],
    initCondition: {
      sm: isInit,
      md: isInit,
      lg: isInit,
      xl: isInit
    },
    controls
  };

  new Slider(options).init();
}();
