import uuid from '@skillbox/request-id';

import { initPhoneMask } from '../libs/input-phone/scripts';
import activeCloudPayments from '../libs/cloudpayments';
import { submitFormGtmTag, successFormGtmTag, failFormGtmTag } from '../libs/gtm';
import { pushDSPCounter } from '../libs/pixel';

import { UpdateType } from '../utils/const';
import { getParams } from '../utils/common';
import { setCookie } from '../utils/cookies';

import { showAlchemerePopup } from '../components/Alchemere/index';
import { showAutopaymentPopup } from '../components/popups';

import FormValidator from './Validator/FormValidator';
import { getToken, getCurrentFormTypes, throwError, setBooleanFieldData } from './actions';
import { Method, PaymentType, ClassName, SuccessHTTPStatusRange } from './const';

const ADDITIONAL_COUNTRY_CODE = '8';
const RU_AND_KZ_CODE = '7';
const TEN_SECONDS = 1000 * 10;
const isNeedAutocomplete = window.cFormAutocomplete && window.cFormAutocomplete.isNeedAutocomplete;

window.$paymentData = {};

export default class Form {
  constructor({ form, rules, vkAuthModel, blockName, autopaymentData = {} } ) { // TODO: autopaymentData - временное решение, нужно для теста тарифов (WEBDEV-157737)
    this._form = form;
    this.blockTriggerName = blockName
    this._formType = this._form.dataset.formType;
    this._validator = new FormValidator(this._form);
    this._button = this._form.querySelector('button[type=submit]');
    this._phoneInput = this._form.querySelector(`.${ClassName.INPUT}[name=phone]`);
    this._fields = [...form.querySelectorAll(`.${ClassName.FIELD}`)];
    this._checkboxes = [...form.querySelectorAll(`.${ClassName.CHECKBOX}`)];
    this._rules = rules;

    this._formData = null;
    this._autopaymentData = autopaymentData;
    this.onCloudPayment = null;
    this.onFormSend = null;
    this.isValid = false;

    this._model = vkAuthModel;
    this._isStartInput = false;

    this._clickFormButtonHandler = this._clickFormButtonHandler.bind(this);
    this._handleModelEvent = this._handleModelEvent.bind(this);
    this._clickCloseSuccessHandler = this._clickCloseSuccessHandler.bind(this);
    this._changeFormRRHandler = this._changeFormRRHandler.bind(this);
  }

  init() {
    this._phoneInput && initPhoneMask(this._form, this._phoneInput);
    this._setFieldHandler();
    this._button.addEventListener('click', this._clickFormButtonHandler);
    this._model?.addObserver(this._handleModelEvent);

    setTimeout(() => {
      this._autoCompleteFields(this._model?.userData);
    }, 0);

    this._form.addEventListener('input', () => {
      this._saveDataToAutocomplete();
    })

    // RR
    const rrForm = this._form.dataset.rr === 'addToCart';
    rrForm && this._form.addEventListener('change', this._changeFormRRHandler);
  }


  get _gtm() {
    return this._form.dataset.gtm
  }

  _handleModelEvent(updateType, update) {
    if (updateType === UpdateType.CONNECT) {
      this._autoCompleteFields(update);
    }
  }

  _getPhoneNumber(number) {
    const countryCode = this._phoneInput.iti.selectedCountryData.dialCode;

    if (!countryCode) {
      return ''
    }

    const iso2 = this._phoneInput.iti.selectedCountryData.iso2;
    let phone = String(number).replace(/\D/g, '');
    let phoneCountry = phone.slice(0, countryCode.length);

    if (phoneCountry === ADDITIONAL_COUNTRY_CODE) {
      phone = phone.replace(phoneCountry, RU_AND_KZ_CODE);
      phoneCountry = RU_AND_KZ_CODE
    }

    if ((iso2 === 'ru' && /^7(?=7)/.test(phone)) || (iso2 === 'kz' && /^7(?=9)/.test(phone))) {
      return '';
    }

    if (phoneCountry === countryCode) {
      return phone.replace(phoneCountry, '');
    }

    return '';
  }

  _autoCompleteFields(data) {
    // заполняем форму данными из куки SKB_autocomplete. плагин cFormAutocomplete
    if (isNeedAutocomplete || data) {
      // TODO: Отрефакторить после обновления библиотеки или после внедрения масок на все проекты
      const transformData = (data) => {
        return {
          ...data,
          phone: this._phoneInput && this._phoneInput.iti
            ? this._getPhoneNumber(data.phone)
            : null
        }
      };
      const currentData = data ? { ...window.cFormAutocomplete.userData, ...data} : window.cFormAutocomplete.userData;
      const userData = transformData(currentData);

      Object.keys(userData).forEach((key) => {
        const value = userData[key];

        if (value) {
          const field = this._form.querySelector(`input:not([type="hidden"])[name="${key}"]`);

          if (field) {
            field.value = value;
            field.dispatchEvent(new Event('change'));
          }
        }
      });
    }
  }

  _setDisabled(isDisabled) {
    this._button.disabled = isDisabled;
    isDisabled
      ? this._button.classList.add(ClassName.LOADING)
      : this._button.classList.remove(ClassName.LOADING);
  }

  _setActiveLabel(field) {
    field.classList.add(ClassName.ACTIVE);
  }

  _changeActiveLabel(field, target) {
    !target.value
      ? field.classList.remove(ClassName.ACTIVE)
      : field.classList.add(ClassName.ACTIVE);
  }

  _setFieldHandler() {
    this._checkboxes.forEach((checkbox) => {
      const input = checkbox.querySelector(`.${ClassName.CHECKBOX_INPUT}`);
      const rule = this._rules[input.name];

      checkbox.addEventListener('change', () => {
        this._validator.check(checkbox, input, rule, 'change');
      });
    });

    this._fields.forEach((field) => {
      const input = field.querySelector(`.${ClassName.INPUT}`);
      const rule = this._rules[input.name];

      input.addEventListener('focus', () => {
        this._validator.reset(field);
        this._setActiveLabel(field);
      });
      input.addEventListener('blur', () => {
        input.value.trim() === ''
          ? this._validator.reset(field)
          : this._validator.check(field, input, rule, 'change');

        this._changeActiveLabel(field, input);
      });
      input.addEventListener('change', () => {
        this._changeActiveLabel(field, input);
      });
      input.addEventListener('input', () => {
        this._validator.check(field, input, rule, 'input');
      });
    })
  }

  _checkValidFields(fields) {
    return !fields.map((field) => {
      const input = field.querySelector(`.${ClassName.INPUT}`);
      const rule = this._rules[input.name];

      return this._validator.check(field, input, rule, 'submit');
    }).includes(false);
  }

  _checkValidBoxes(boxes) {
    return !boxes.map((box) => {
      const input = box.querySelector(`.${ClassName.CHECKBOX_INPUT}`);
      const rule = this._rules[input.name];

      return this._validator.check(box, input, rule, 'submit');
    }).includes(false);
  }

  _setSubmitMessage(isSuccess) {
    const formWrapper = this._form.parentElement;
    const tooltipCloseButton = this._form.querySelector('.message__close');

    const closeErrorMessage = () => {
      formWrapper.classList.remove(ClassName.ERROR);
      tooltipCloseButton.removeEventListener('click', closeErrorMessage);
    }

    if (isSuccess) {
      const successCloseButton = formWrapper.querySelector(`.${ClassName.SUCCESS_CLOSE}`);

      formWrapper.classList.remove(ClassName.ERROR);
      formWrapper.classList.add(ClassName.SUCCESS);
      successCloseButton && successCloseButton.addEventListener('click', this._clickCloseSuccessHandler);
    } else {
      formWrapper.classList.add(ClassName.ERROR);
      tooltipCloseButton.addEventListener('click', closeErrorMessage);
    }
  }

  _savePaymentData(data) {
    window.$paymentData = data;
  }

  _saveDataToAutocomplete() {
    window.cFormAutocomplete && window.cFormAutocomplete.saveData({
      type: 'formData',
      data: this._formData || new FormData(this._form)
    });
  }

  _redirectToGbPoll(email) {
    const url = `https://gb.ru/users/questionnaires/next/?email=${email}&anketa_show=a2&anketa_check=a1,a2,m1,m2&to=https://gb.ru/education`;

    setTimeout(() => {
      window.location.href = url;
    }, TEN_SECONDS)
  }

  _getAllValues() {
    return getCurrentFormTypes(this._formType).isAutoPayment
      ? window.$paymentData
      : {
        name: this._formData.get('name'),
        email: this._formData.get('email'),
        phone: this._formData.get('phone'),
        requestId: this._formData.get('request_id')
      }
  }

  _setAutopayData(email, requestId) {
    this._formData.append('email', email);
    this._formData.append('request_id', requestId);
  }

  _setRequestId() {
    // Не нужно обновлять requestId для попапа автооплат
    if (!getCurrentFormTypes(this._formType).isAutoPayment) {
      /* eslint-disable-next-line */
      const requestId = uuid.generateRequestId(this._form.dataset.gw || gwPrefix);
      this._formData.append('request_id', requestId);
      setCookie('requestId', requestId);
    }
  }

  _pushSuccessAnalytics({email, requestId}) {
    successFormGtmTag(this._gtm, requestId);
    pushDSPCounter('KHswCpHj');

    if (email) {
      window.$retailRocket?.sendEvent('setContact', email)
      /*eslint-disable */

      window.$retailRocket?.sendEvent('order', {
        requestId,
        productId: rr?.id,
        price: rr?.price
      })
      /*eslint-enable */
    }
  }

  _pushFailedAnalytics(errorCode, error) {
    const {email, phone, requestId} = this._getAllValues();

    failFormGtmTag(this._gtm, {
      requestId,
      phone,
      email,
      errorCode,
      response: error
    });
  }

  _showCloudPayment() {
    // eslint-disable-next-line no-undef
    activeCloudPayments(this._autopaymentData, (isRussianDomain && !isLernaLanding))
  }

  _onCloudPayment() {
    if (typeof this.onCloudPayment === 'function') {
      this.onCloudPayment();
    }
  }

  _onFormSend() {
    if (typeof this.onFormSend === 'function') {
      this.onFormSend();
    }
  }

  _emitFormEvent(form, params, name = 'form-sended') {
    const customEvent = new Event(name);

    customEvent.sendingResult = params;
    form.dispatchEvent(customEvent);
  }

  _submitForm() {
    const {name, email, phone, requestId} = this._getAllValues();
    const {isAutoPayment, isPayMethods, isLead} = getCurrentFormTypes(this._formType);
    const redirectType = this._form.dataset.redirectType;
    const paymentType = this._formData.get('payment_type');

    submitFormGtmTag(this._gtm, requestId);

    if (!isAutoPayment) {
      const landingTag = getParams('tag');
      landingTag && this._formData.append('tag', landingTag);
      this._savePaymentData({name, email, phone, requestId});
    } else {
      this._setAutopayData(email, requestId);
    }

    this._emitFormEvent(this._form, {
      formData: this._formData
    });

    if (isAutoPayment && paymentType === PaymentType.CLOUDPAYMENTS) {
      this._showCloudPayment();
      this._onCloudPayment();

      this._setDisabled(false);
      return;
    }

    setBooleanFieldData(this._form, this._formData, 'promo_accepted');

    fetch(this._form.action, {
      method: Method.POST,
      body: this._formData,
      headers: {'Accept': 'application/json'}
    })
      .then(response => this._checkStatus(response))
      .then(async response => {
        window.$growthbookClient?.getFeatureValue('landgen-test-v2')

        // В модалке алхимера, для лерны требуются данные из отправленной формы
        window.$lastSendedFormData = {
          requestId,
          phone,
          name,
          email
        };

        this._emitFormEvent(this._form, { formData: this._formData }, 'form-success');

        window.$analytics && window.$analytics.push('form-submit');

        this._setSubmitMessage(true);
        !isGbHost && this._pushSuccessAnalytics({ email, requestId }); // eslint-disable-line

        this._saveDataToAutocomplete();
        this._onFormSend();
        this._emitFormEvent(document.body);

        /* eslint-disable-next-line */
        if (autopayment.public_ID && isLead) {
          this._emitFormEvent(this._form, {}, 'form-autopayment');

          showAutopaymentPopup({
            type: this._formData.get('submit_type') ?? 'consult',
            blockName: this.blockTriggerName || 'Блок без названия',
            callback: () => redirectType && showAlchemerePopup(redirectType)
          });

          return;
        }

        /* eslint-disable-next-line */
        if (paymentType === PaymentType.CLOUDPAYMENTS) {
          this._showCloudPayment();
          this._onCloudPayment();
          return;
        }

        const { redirect_url } = await response.json();
        if (redirect_url) {
          window.location.href = redirect_url;
          return;
        }

        // Редирект на опрос в  GB
        /* eslint-disable-next-line */
        if (isGbHost && !autopayment.public_ID) {
          this._redirectToGbPoll(email);
          return;
        }

        // Показать попап с опросом Alhemere
        if (redirectType && (!isPayMethods || (paymentType === PaymentType.CONSULTATION))) {
          this._emitFormEvent(this._form, {}, 'form-alchemere');
          showAlchemerePopup(redirectType);
        }
      })
      .catch((error) => {
        this._setSubmitMessage(false);
        window.Sentry.captureException(error, { extra: error.options });
      })
      .finally(() => {
        this._setDisabled(false);
      });
  }

  _changeFormRRHandler() {
    if (!this._isStartInput) {
      this._isStartInput = true;
      /*eslint-disable */
      window.$retailRocket?.sendEvent('addToCart', rr?.id)
      /*eslint-enable */
    }
  }

  _clickCloseSuccessHandler(evt) {
    evt.preventDefault();
    this._form.parentElement.classList.remove(ClassName.SUCCESS);
  }

  async _clickFormButtonHandler(evt) {
    evt.preventDefault();

    this.isValid = this._checkValidFields(this._fields) && this._checkValidBoxes(this._checkboxes)

    if (this.isValid) {
      this._setDisabled(true);
      this._formData = new FormData(this._form);
      this._formData.append('form_url', `${window.location.origin}${window.location.pathname}`);

      try {
        const token = await getToken();
        this._formData.append('recaptcha_token', token);
        this._setRequestId();
        this._submitForm();
      } catch (e) {
        this._setDisabled(false);
        this._setSubmitMessage(false);
        window.Sentry.captureException(`Не удалось получить Token: ${e}`);
      }
    }
  }

  async _checkStatus(response) {
    if (
      response.status < SuccessHTTPStatusRange.MIN ||
      response.status > SuccessHTTPStatusRange.MAX
    ) {
      try {
        const { data } = await response.json();
        const message = JSON.stringify(data);

        !isGbHost && this._pushFailedAnalytics(response.status, message); // eslint-disable-line

        throwError(`Error in fetch, status: ${response.status}`, {
          block: this.blockTriggerName,
          status: response.status,
          url: response.url,
          serverMessage: message
        });
      } catch (error) {
        const options = error.options
          ? error.options
          : {
            block: this.blockTriggerName,
            status: response.status,
            url: response.url,
            errorMessage: error.message
          }

        throwError(`Error in fetch, status: ${response.status}`, options);
      }
    }

    return response;
  }
}
