import { Slider } from './Slider/Slider';

export const slidersInit = () => {
  const blocks = document.querySelectorAll('[data-slider]');

  const SPACE_BETWEEN_768 = {
    'modal': 0,
    'fullscreen': 16,
    'divisionscreen': 20,
    'cards': 24
  };

  const SPACE_BETWEEN_1280 = {
    'modal': 0,
    'fullscreen': 40,
    'divisionscreen': 30,
    'cards': 32
  };

  const INIT_CONDITION = {
    'modal': (count) => ({ sm: count > 1, md: count > 1 }),
    'fullscreen': (count) => ({ sm: count > 1, md: count > 1 }),
    'divisionscreen': (count) => ({ sm: count > 1, md: count > 3 }),
    'cards': (count) => ({ sm: count > 1, md: count > 1 }),
  };

  if (blocks.length) {
    blocks.forEach(block => {
      const sliderType = block.dataset.slider;
      const wrapper = block.querySelector('.js-slider');
      const slides = block.querySelectorAll('.js-slider-item');
      const controls = block.querySelector('.js-slider-controls');

      const options = {
        wrapper,
        settings: {
          observer: sliderType === 'modal',
          observeParents: sliderType === 'modal',
          spaceBetween: 10,

          effect: sliderType === 'modal' ? 'fade' : 'slide',
          fadeEffect: {
            crossFade: true
          },

          slideClass: 'js-slider-item',
          slideActiveClass: 'js-slider-item-active',
          wrapperClass: 'js-slider-list',

          pagination: {
            el: '.js-slider-count',
            type: 'fraction',
          },

          navigation: {
            nextEl: '.js-slider-next',
            prevEl: '.js-slider-prev',
            disabledClass: 'button-arrow--disabled'
          },

          media: {
            lg: {
              effect: (sliderType === 'fullscreen' || sliderType === 'modal') ? 'fade' : 'slide'
            },
            xl: {
              effect: (sliderType === 'fullscreen' || sliderType === 'modal') ? 'fade' : 'slide'
            }
          },

          breakpoints: {
            768: {
              spaceBetween: SPACE_BETWEEN_768[sliderType]
            },
            1024: {
              spaceBetween: sliderType === 'fullscreen' ? 40 : 30
            },
            1280: {
              spaceBetween: SPACE_BETWEEN_1280[sliderType]
            }
          }
        },
        modules: ['pagination', 'navigation', 'effectFade', 'a11y'],
        initCondition: {
          sm: INIT_CONDITION[sliderType](slides.length).sm,
          md: INIT_CONDITION[sliderType](slides.length).md,
          lg: INIT_CONDITION[sliderType](slides.length).md,
          xl: INIT_CONDITION[sliderType](slides.length).md
        },
        controls
      };

      new Slider(options).init();
    })
  }
};
