import { RetailRocketSkillbox } from '@skillbox/retail-rocket'

/*eslint-disable */
export const retailRocketInit = async () => {
  if (typeof rr === 'undefined' || !rr) {
    return
  }

  const options = {
    blocks: ['landgen'],
    skillboxBaseUrl: rr.skillboxApiUrl,
    landgenBaseUrl: rr.landgenApiUrl,
    isSaleFeed: false,
    errorHandlerCallback: window.Sentry.captureException || console.error,
  }

  window.$retailRocket = new RetailRocketSkillbox(options)
  rr.id && window.$retailRocket?.sendEvent('openCard', rr.id)
};
/*eslint-enable */
