class $Analytic {
  constructor(customOptions, initCallback) {
    this.listeners = {}

    this.options = {
      isLogEnable: false,
      platform: '',
      pageName: '',
      fullPath: '',
      ...customOptions
    }

    if (initCallback && typeof initCallback === 'function') {
      initCallback()
    }
  }

  push(eventName, data = null) {
    const handlers = this.listeners[eventName]

    this.log(eventName, data)

    if (handlers && handlers.length) {
      handlers.forEach((fnc) => {
        try {
          fnc(data, this.options)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          window.Sentry.captureException(e)
        }
      })
      return
    }

    this.log(eventName, 'Listener not found')
  }

  log(event, data) {
    if (this.options.isLogEnable) {
      // eslint-disable-next-line no-console
      console.log(event, data)
    }
  }

  createEvent(eventName, listener) {
    const isListOfListeners = Array.isArray(listener)

    if (!this.listeners[eventName]) {
      this.listeners[eventName] = []
    }

    if (isListOfListeners) {
      this.listeners[eventName].push(...listener)
    } else {
      this.listeners[eventName].push(listener)
    }
  }

  setOptions(options) {
    this.options = { ...this.options, ...options }
  }
}

const getGtmProvider = () => {
  if (!window.dataLayer) {
    window.dataLayer = []
  }

  return window.dataLayer
}

const getFlocktoryProvider = () => {
  window.flocktory = window.flocktory || []

  return window.flocktory
}

const $analytics = new $Analytic({
  platform: 'landgen',
  provider: {
    $gtm: getGtmProvider(),
    $flocktory: getFlocktoryProvider()
  }
})

export default $analytics
