import { SkbSession } from '@skillbox/user-session'

const isProduction = environment === 'production'

window.userSession = new SkbSession({
  project: 'landgen',
  enableDadata: true,
  isProduction,
  // eslint-disable-next-line no-undef
  nomenclatureId: typeof landingInfo !== 'undefined' ? landingInfo.nomenclature_ID : null
})

window.userSession.initPromise = window.userSession.init()
