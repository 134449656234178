import "./style.scss";

export default !(function() {
  const block = document.querySelector(".lms");

  if (!block) {
    return;
  }

  let playPromise = null
  const videos = [...block.querySelectorAll(".lms__media--video video")];

  if (videos.length) {
    const toggleVideoPlay = ([entry]) => {
      const video = entry.target;

      if (!playPromise && entry.intersectionRatio === 1) {
        playPromise = video.play();
      } else {
        playPromise?.then(() => {
          video.pause();
          playPromise = null;
        })
      }
    };

    videos.forEach(video => {
      const observer = new IntersectionObserver(toggleVideoPlay, {
        threshold: [0, 1]
      });
      observer.observe(video);
    });
  }
})();
