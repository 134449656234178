import { ClientIds } from '@skillbox/client-ids'

const logger = {
  log: window.Sentry?.captureException || console.error
}

const clientIds = new ClientIds(logger)

window.clientIds = {
  getSkbId: () => clientIds.getSkbId(),
  getGaId: async () => await clientIds.getGaId()
}
