import sentryIgnoreErrors from '@skillbox/sentry-ignore-errors'
import * as Sentry from '@sentry/browser';

export const sentryInit = () => {
  /*eslint-disable */
  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
      environment: environment,
      ignoreErrors: sentryIgnoreErrors.errors,
      integrations: [
        Sentry.browserTracingIntegration({
          tracePropagationTargets: [/^(https:\/\/)(.*)(skillbox.ru\/api\/|selcdn.ru).*/]
        })
      ],
      tracesSampleRate: 0.2
    });
  }

  window.Sentry = {
    captureException: (error, extra) => {
      Sentry.captureException(error, extra);
    },
  };
  /*eslint-enable */
};
