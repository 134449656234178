import UiModal from '../../../../js/components/UiModal/UiModal';
import { scrollToId } from '../../../../js/components/button-actions';

const disclaimerTemplate =  document.querySelector('#disclaimer-modal');
const disclaimerModalTemplate = disclaimerTemplate && disclaimerTemplate
  .content
  .querySelector('.ui-disclaimer');

export default class DisclaimerModal extends UiModal {
  constructor(blockName) {
    super(disclaimerModalTemplate);

    this.button = null;
    this.blockName = blockName;
    this._buttonClick = this._buttonClick.bind(this);
  }

  beforeMounted() {
    this.button = this.element.querySelector('.ui-disclaimer__button');
    this.button && this.button.addEventListener('click', this._buttonClick);
  }

  _buttonClick(evt) {
    evt.preventDefault();

    scrollToId(this.button);

    window.$analytics?.push('all-block-consult-button-click', {
      blockName: this.blockName,
      buttonType: 'scroll',
      buttonText: this.button.textContent
    })

    this.close();
  }

  beforeUnmounted() {
    this.button && this.button.removeEventListener('click', this._buttonClick);
  }
}
