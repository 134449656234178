import { UpdateType } from '../../../../js/utils/const';
import { render, createElement } from '../../../../js/utils/render';
import { getSpeakerTemplate, getLoaderTemplate, getContentTemplate } from './speaker.template';
import { DetailsModal } from '../../../../js/components/DetailsModal/DetailsModal';
import UiModal from '../../../../js/components/UiModal/UiModal';

export class Speaker extends UiModal {
  constructor(card, list, speakerModel, hasWorks, blockName) {
    const template = createElement(getSpeakerTemplate());

    super(template, {
      wrapperClass: `speakers-v3-popup ${hasWorks ? 'speakers-v3-popup--centered' : ''}`,
      isCenter: hasWorks
    });

    this._speakerModel = speakerModel;

    this.blockName = blockName
    this._card = card;
    this._list = list;
    this._id = card.dataset.id;
    this._isLoading = true;

    this._onSpeakerEvent = this._onSpeakerEvent.bind(this);
    this._clickPictureHandler = this._clickPictureHandler.bind(this);

    this._popupContainer = null;
    this._loaderElement = null;
    this._contentElement = null;
    this._messageElement = null;
  }

  async beforeMounted() {
    this._speakerModel.addObserver(this._onSpeakerEvent);
    this._popupContainer = this.element.querySelector('.speakers-v3-popup__content');
    this.element.addEventListener('click', this._clickPictureHandler);
    this._renderContent();

    await this._setCardData(this._card);
  }

  getTemplate() {
    return getSpeakerTemplate();
  }

  async _setCardData(card) {
    this._card = card;
    this._id = this._card.dataset.id;
    this._isLoading = !this._speakerModel.getSpeaker(this._id);
    await this._speakerModel.loadData(this._id);
  }

  _onSpeakerEvent(updateType) {
    this._isLoading = false;
    this._removeLoading();

    switch (updateType) {
      case UpdateType.INIT:
        this._renderContent();
        break;

      case UpdateType.ERROR:
        this._renderMessage('Похоже, произошла ошибка. Попробуйте перезагрузить страницу.');
        break;
    }
  }

  _renderLoading() {
    this._loaderElement = createElement(getLoaderTemplate());
    render(this._popupContainer, this._loaderElement);
  }

  _removeLoading() {
    if (this._loaderElement !== null) {
      this._loaderElement && this._loaderElement.remove();
      this._loaderElement = null;
    }
  }

  _renderMessage(message) {
    this._messageElement = createElement(`<p class="p p--2" style="margin-top: 50px; text-align: center;">${message}</p>`);
    render(this._popupContainer, this._messageElement);
  }

  _renderPreviews() {
    const data = this._speakerModel.getSpeaker(this._id);
    this._contentElement = createElement(getContentTemplate(data));
    render(this._popupContainer, this._contentElement);
  }

  _removePreviews() {
    if (this._contentElement !== null) {
      this._contentElement.remove();
      this._contentElement = null;
    }
  }

  _renderContent() {
    if (this._isLoading) {
      this._renderLoading();
      return;
    }

    this._renderPreviews();
  }

  _clickPictureHandler(evt) {
    const target = evt.target;
    const detailsButton = target.closest('[data-details="button"]');

    if (detailsButton) {
      const pictures = [];
      this._speakerModel.getSpeaker(this._id).groups
        .forEach((group) => group.previews.forEach((preview => {
          const picture = preview.picture;

          picture && pictures.push({
            media: {
              picture: {
                webp: picture.webp,
                img: picture.image,
                alt: ''
              }
            }
          });
        })));

      new DetailsModal(pictures, detailsButton.dataset.index - 1, this.blockName).init();
      window.$analytics && window.$analytics.push('teachers-works-click');
    }
  }

  beforeUnmounted() {
    this._speakerModel.removeObserver(this._onSpeakerEvent);
    this.element.removeEventListener('click', this._clickPictureHandler);
  }
}
