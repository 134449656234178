import UiModal from '../../../../js/components/UiModal/UiModal'
import Form from '../../../../js/Form/Form'
import { defaultRules } from '../../../../js/Form/default-rules'
import { getVkAuthModel } from '../../../../js/components/VKConnect'
import { createElement, render, RenderPosition } from '../../../../js/utils/render'

const getHeaderTemplate = ({ title, description }) => (
  `<header class="order-modal__header ui-typography-group">
    <h3 class="order-modal__title h h--6">
      ${title}
    </h3>

    ${description
      ? `<p class="order-modal__desc p p--2">
          ${description}
        </p>`
      : ''
    }
  </header>`
)

class OrderModal extends UiModal {
  /**
   * @param {HTMLElement} template
   * @param {Object} options
   * @param {String} options.blockName
   * @param {Object} options.header
   * @param {String} options.header.title
   * @param {String} options.header.description
   * @param {Array} options.hiddenFields
   * @param {Function} options.submitHandler
   * @param {Function} options.closeHandler
   */
  constructor(template, options) {
    super(template, {
      wrapperClass: 'order-modal',
      isCenter: options.isCenter ?? true,
      outerScroll: true,
      blockTriggerName: options.blockName
    })

    this.form = null
    this.content = template

    this.header = options.header ?? JSON.parse(template.dataset.header)
    this.hiddenFields = this.getHiddenFields(JSON.parse(template.dataset.hiddenFields), options.hiddenFields)
    this.submitHandler = options.submitHandler
    this.closeHandler = options.closeHandler
  }

  beforeMounted() {
    this.form = this.element.querySelector('.form')

    this.renderHeaderElement()
    this.initForm()
  }

  beforeUnmounted() {
    this.form.removeEventListener('form-sended', this.submitFormHandler);
  }

  beforeClose() {
    this.closeHandler?.()
  }

  async initForm() {
    const vkConnectContainer = this.element.querySelector('[data-auth="vk-connect"]')
    const vkAuthModel = await getVkAuthModel([vkConnectContainer])

    this.renderHiddenFields(this.form, this.hiddenFields)
    this.form.addEventListener('form-sended', this.submitFormHandler)
    this.form.addEventListener('form-autopayment', this.close)
    this.form.addEventListener('form-alchemere', this.close)

    new Form({
      vkAuthModel,
      form: this.form,
      rules: defaultRules,
      blockName: this.blockTriggerName
    }).init()
  }

  getHiddenFields(mainFields, newFields) {
    if (!newFields) {
      return mainFields
    }

    const fieldsMap = [...mainFields, ...newFields].reduce((acc, item) => {
      acc[item.name] = item.value
      return acc
    }, {})

    return Object.keys(fieldsMap).map(name => ({ name, value: fieldsMap[name] }))
  }

  submitFormHandler = (evt) => {
    const requestId = evt.sendingResult?.formData?.get('request_id')

    window.$analytics?.push('consult-form-send', {
      requestId,
      blockName: this.blockTriggerName
    });

    this.submitHandler?.(evt)
  }

  renderHiddenFields(container, hiddenFields) {
    const fragment = document.createDocumentFragment()

    hiddenFields.forEach(({ name, value }) => {
      const hiddenField = createElement(`<input name="${name}" type="hidden" value="${value}">`)
      render(fragment, hiddenField)
    })

    render(container, fragment)
  }

  renderHeaderElement() {
    render(this.content, createElement(getHeaderTemplate(this.header)), RenderPosition.AFTERBEGIN)
  }
}

export const showOrderModal = (options) => {
  const template = document.querySelector('#order-modal')?.content
    .cloneNode(true).querySelector('.order-modal__wrapper');

  if (!template) {
    const err = new Error(`[Order Modal]: шаблон с id=order-modal отсутствует`)
    console.error(err)
    window.Sentry?.captureException(err)
    return
  }

  const orderModal = new OrderModal(template, options)
  orderModal.init()

  return orderModal
}
