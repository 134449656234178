import {getCookie} from '../utils/cookies';
import {showAlchemerePopup} from '../components/Alchemere/index';
import getReceiptSettings from '@skillbox/autopayment'

/* eslint-disable*/
const sendCloudpaymentHook = ({ data }) => {
  fetch('/api/v1/gateway/widget/show', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      request_id: data.request_id,
      nomenclature_id: data.nomenclature_id,
      payment_type: 'cloudpayments'
    })
  })
    .catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    })
};

const getCloudpaymentsData = async (autopaymentData) => {
  const paymentData = window.$paymentData;

  // TODO: отрефакторить передачу данных autopaymentData, добавлено в рамках задачи по тарифам WEBDEV-157737 (так как у тарифа другая номенклатура)
  const data = {
    ...autopayment,
    ...autopaymentData
  };

  const { vat, method, object, taxationSystem } = await getReceiptSettings(environment, (err) => {
    console.error(err);
    window.Sentry.captureException(err);
  });

  const receiptData = {
    Items: [{
      label: data.description, //наименование товара
      price: data.price, //цена
      quantity: 1.00, //количество
      amount: data.price, //сумма
      vat, //ставка НДС
      method,
      object,
    }],
    calculationPlace: data.landingUrl,
    taxationSystem,
    email: paymentData.email,
    amounts: {
      electronic: data.price
    }
  };

  return {
    publicId: data.public_ID, // id из личного кабинета
    description: data.description, // назначение
    amount: data.price, // сумма
    currency: data.currency_code, // валюта
    invoiceId: paymentData.requestId,
    accountId: paymentData.email, // идентификатор плательщика
    email: paymentData.email,
    url: data.landingUrl,
    data: {
      dynamic_discount: data.dynamic_discount,
      source_slug: data.service,
      name: paymentData.name,
      phone: paymentData.phone,
      email: paymentData.email,
      landing_id: data.landing_ID,
      source_nomenclature: data.source_nomenclature,
      //  TODO: service - можно будет выпилить в будущем
      service: data.service,
      nomenclature_id: data.nomenclature_ID,
      request_id: paymentData.requestId,
      country_code: data.country_code,
      currency_code: data.currency_code,
      advcake_trackid: getCookie('advcake_trackid'),
      advcake_url: getCookie('advcake_url'),
      admitad_uid: getCookie('admitad_uid'),
      cloudPayments: {
        CustomerReceipt: receiptData,
      },
    }
  };
};

const activeCloudPayments = async (autopaymentData, isNeedToRedirectOnThanksPage) => {
  const cloudPaymentsData = await getCloudpaymentsData(autopaymentData)
  const widget = new cp.CloudPayments()

  widget.pay('charge', cloudPaymentsData, {
    onSuccess: () => {
      const email = window.$paymentData?.email

      if (isNeedToRedirectOnThanksPage && email) {
        // если ру лендинг, то редиректим на thank-you
        window.open(`https://skillbox.ru/thank-you/?email=${email}`, '_blank')
      } else {
        showAlchemerePopup('order')
      }
    }
  })

  sendCloudpaymentHook(cloudPaymentsData)
};

export default activeCloudPayments;
