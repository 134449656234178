import './style.scss'

import { UniversalNotice } from '@skillbox/promo-banner/dist/UniversalNotice/scripts.js'
import { showOrderModal } from '../../popups/v1/OrderModal/scripts'
import { saleEndDate } from '../../../js/components/SaleEndDate/SaleEndDate'

export default !async function () {
  const block = document.querySelector('.universal-notice-wrapper')

  if (!block) {
    return
  }

  // TODO: Удалить все что касается черной пятницы после акции
  const noticeType = block.dataset.noticeType
  const isCourse = block.dataset.landingType === 'course'

  const header = {
    nyj: {
      title: isCourse ? 'Скидки до 60%' : 'Скидки до&nbsp;60% и&nbsp;3&nbsp;курса в&nbsp;подарок',
      description: 'Смените профессию, обучаясь на&nbsp;практике!'
    },
    ny: {
      title: isCourse ? 'Скидки до 60%' : 'Скидки до&nbsp;60% и&nbsp;3&nbsp;курса в&nbsp;подарок',
      description: 'Дарите новые профессии себе и&nbsp;близким&nbsp;🎁'
    }
  }

  const clickBannerHandler = (evt) => {
    evt.preventDefault()

    const blockName = block.closest('[data-block-name]')?.dataset.blockName ?? 'Блок без названия'

    const modal = showOrderModal({
      isCenter: false,
      hiddenFields: [{ name: 'type', value: 'event' }],
      blockName,
      header: noticeType ? header[noticeType] : null
    })

    if (header[noticeType]) {
      const header = modal?.content?.querySelector('.order-modal__header')

      if (header) {
        header.style.textAlign = 'left'
        header.style.marginLeft = 0
      }
    }
    !isGbHost && window.$analytics?.push('fl-show-form') // eslint-disable-line
    window.$analytics?.push('all-block-learn-more-click', { blockName })
  }

  const promoBanner = new UniversalNotice({ onClick: clickBannerHandler })
  saleEndDate.addObserver(time => promoBanner.setTimer(time))
}()
