import { SmartModalController } from './SmartModalController'

const startSmartPopupTest = () => {
  window.$growthbookClient.getFeatureValue('smart_popup_forbidden_utms')
  window.$growthbookClient.getFeatureValue('smart_popup_threshold')
}

export const smartPopupInit = () => {
  if (!window.userSession) {
    window.Sentry.captureException(new Error('[smart-popup]: библиотека user-session не проинициализирована'))
    return
  }

  if (!window.$growthbookClient) {
    window.Sentry.captureException(new Error('[smart-popup]: библиотека growthbook не проинициализирована'))
    return
  } else {
    startSmartPopupTest() // TODO WEBDEV-171826 a/b/c тест смартпопапа
  }

  if (!window.$growthbookClient.getFeatureValue('smart_popup_forbidden_utms') || !window.$growthbookClient.getFeatureValue('smart_popup_threshold')) {
    window.Sentry.captureException(new Error('[smart-popup]: фичи smart-popup_forbidden_utms или smart_popup_threshold отсутствуют в библиотеке growthbook'))
    return
  }

  const utmList = window.$growthbookClient.getFeatureValue('smart_popup_forbidden_utms')[0].value.utmCampaignBlackListForSmartPopup
  const isUtmFromList = utmList?.some(item => window.location.search.includes(item))
  const { value: showThreshold, experimentResult } = window.$growthbookClient.getFeatureValue('smart_popup_threshold')[0]
  // в группе А или в отключенном тесте проверяем наличие utm в черном списке
  const isCheckedUtmForGroupA = !experimentResult || experimentResult?.variationId === 0 ? !isUtmFromList : true
  const isSmartPopupsAvailable = typeof isSmartModal !== 'undefined' && isSmartModal && isCheckedUtmForGroupA // eslint-disable-line

  if (isSmartPopupsAvailable) {
    new SmartModalController(showThreshold)
  }
}
