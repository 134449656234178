import './style.scss'

export default !async function () {
  const block = document.querySelector('.breadcrumbs-v1')

  if (!block) {
    return
  }

  const { UiBreadcrumbs } = await import('@skillbox/front-box/dist/Navigation/UiBreadcrumbs/scripts/index.js')
  new UiBreadcrumbs()
}()
