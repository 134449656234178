import { UiIconButtonVanilla } from '@skillbox/front-box/dist/Buttons/UiIconButton/UiIconButtonVanilla'

const getPlayButtonTemplate = (video) => (
  `<button
    class="details-modal__play js-project-video"
    type="button"
    aria-label="Воспроизвести видео"
    data-player-type="video"
    data-video="${video.src}"
  >
    <svg
      fill="none"
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <rect width="56" height="56" rx="28" fill="currentColor" />
      <path d="m23 21 12.1 7L23 35V21Z" fill="#fff" />
    </svg>
  </button>`
)

const getMediaTemplate = ({ picture, video }) => {
  const image = picture ? picture : video.preview.picture

  return (
    `<div class="details-modal__picture ${video ? 'details-modal__picture--video' : ''}">
      ${video ? getPlayButtonTemplate(video) : ''}

      <picture class="details-modal__viewport">
        <source
          srcset="${image.webp}"
          type="image/webp"
        >

        <img
          class="details-modal__content"
          src="${image.img}"
          decoding="async"
          alt="${image.alt}"
        >
      </picture>
    </div>`
  )
}

export const getDetailsModalTemplate = (data, blockName) => {
  const isSlider = data.length > 1;

  return (
    `<div class="details-modal__wrapper ui-container" data-slider-name="${blockName} examples">
      <div class="details-modal__slider">
        <span class="details-modal__count f f--16 f--m"></span>

        <ul class="details-modal__list">
          ${data.map(({ description, media }) => {
            return (
              `<li class="details-modal__item">
                ${getMediaTemplate(media)}

                ${description
                  ? `<p class="details-modal__desc f f--14">
                      ${description}
                    </p>`
                  : ''
                }
              </li>`
            )
          }).join('')}
        </ul>

        <div class="details-modal__controls ${!isSlider ? 'details-modal__controls--no-slider' : ''}">
          ${isSlider
            ? `${UiIconButtonVanilla({
              theme: 'main-white',
              icon: 'ui-arrow-left',
              ariaLabel: 'Предыдущее изображение',
              classes: 'details-modal__button details-modal__button--prev'
            })}`
            : ''
          }

          <div class="details-modal__zoom" data-theme="dark">
            <button
              class="details-modal__zoom-btn"
              data-zoom="out"
              aria-label="Уменьшить изображение"
              disabled="true"
            >
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path fill="currentColor" fill-rule="evenodd" d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z" clip-rule="evenodd"/>
                <path fill="currentColor" d="M16 11a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2h8Z"/>
              </svg>
            </button>

            <span class="details-modal__size f f--16">
              1x
            </span>

            <button
              class="details-modal__zoom-btn"
              data-zoom="in"
              aria-label="Увеличить изображение"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path fill="currentColor" fill-rule="evenodd" d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z" clip-rule="evenodd"/>
                <path fill="currentColor" d="M16 11a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2h8Z"/>
                <path fill="currentColor" d="M13 16a1 1 0 1 1-2 0V8a1 1 0 1 1 2 0v8Z"/>
              </svg>
            </button>
          </div>

          ${isSlider
            ? `${UiIconButtonVanilla({
                theme: 'main-white',
                icon: 'ui-arrow-right',
                ariaLabel: 'Следующее изображение',
                classes: 'details-modal__button details-modal__button--next'
            })}`
            : ''
          }
        </div>
      </div>
    </div>`
  )
};
