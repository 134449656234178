import UiModal from '../../../../js/components/UiModal/UiModal'
import { AutoPaymentForm } from '../AutoPaymentForm/scripts'
import { showAlchemerePopup } from '../../../../js/components/Alchemere'

class AutopaymentModal extends UiModal {
  constructor(template, { nomenclatureId, blockName, methods, type = 'pay' }) {
    super(template, {
      blockTriggerName: blockName,
      isCenter: true,
      wrapperClass: 'autopayment-modal'
    })

    if (!methods) {
      const error = new Error('[Autopayment modal]: data is required')

      window.Sentry?.captureException(error)
      throw error
    }

    this.methods = methods
    this.type = type
    this.nomenclatureId = nomenclatureId

    this.formInstance = null
    this.formElement = template
  }

  beforeMounted() {
    this.renderForm()
    this.formInstance.form.addEventListener('form-success', this.formSuccessHandler)
  }

  mounted() {
    this.formInstance.setDetailsHeight()
  }

  beforeUnmounted() {
    this.formInstance.form.removeEventListener('form-success', this.formSuccessHandler)
  }

  renderForm() {
    this.formInstance = new AutoPaymentForm({
      wrapper: this.contentWrapper,
      template: this.formElement,
      nomenclatureId: this.nomenclatureId,
      methods: this.methods,
      type: this.type,
      blockName: this.blockTriggerName
    })

    this.formInstance.render()
  }

  formSuccessHandler = () => {
    this.close()
  }

  beforeClose() {
    showAlchemerePopup('lead')
  }
}

export const showAutopaymentModal = (template, { methods, nomenclatureId, type, blockName }) => {
  if (!template) {
    return
  }

  if (!methods) {
    const data = JSON.parse(template.dataset.payments)
    methods = data?.[nomenclatureId]
  }

  methods && new AutopaymentModal(template, { nomenclatureId, methods, type, blockName }).init()
}
