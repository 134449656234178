import UiModal from '../../../../js/components/UiModal/UiModal'
import { UpSaleForm } from '../UpSaleForm/scripts'
import { showAlchemerePopup } from '../../../../js/components/Alchemere'
import { Media } from '../../../../js/utils/const'

class UpSaleModal extends UiModal {
  constructor(template, { submitHandler, type, blockName }) {
    super(null, {
      wrapperClass: 'up-sale-modal',
      isCenter: true,
      blockTriggerName: blockName
    })

    this.type = type
    this.submitHandler = submitHandler

    this.formInstance = null
    this.formElement = template

    this.smMax = window.matchMedia(`(max-width: ${Media.SM_MAX}px)`)
  }

  beforeMounted() {
    this.renderForm()

    if (this.smMax.matches) {
      this.scrollingEl.addEventListener('scroll', this.modalScrollHandler)
    }

    this.smMax.addEventListener('change', this.smMediaChangeHandler)
    this.formInstance.form.addEventListener('form-success', this.formSuccessHandler)
  }

  mounted() {
    if (this.smMax.matches) {
      this.setShadowForFooter(this.scrollingEl)
    }
  }

  beforeUnmounted() {
    if (this.smMax.matches) {
      this.scrollingEl.removeEventListener('scroll', this.modalScrollHandler)
    }

    this.smMax.removeEventListener('change', this.smMediaChangeHandler)
    this.formInstance.form.removeEventListener('form-success', this.formSuccessHandler)
  }

  beforeClose() {
    showAlchemerePopup('lead')
  }

  renderForm() {
    this.formInstance = new UpSaleForm(this.contentWrapper, this.formElement, { type: this.type, blockName: this.blockTriggerName })
    this.formInstance.render()
  }

  smMediaChangeHandler = (evt) => {
    if (evt.matches) {
      this.scrollingEl.addEventListener('scroll', this.modalScrollHandler)
    } else {
      this.scrollingEl.removeEventListener('scroll', this.modalScrollHandler)
    }

    this.setShadowForFooter(this.scrollingEl)
  }

  setShadowForFooter(target) {
    const isScroll = (target.scrollHeight - target.offsetHeight) !== target.scrollTop
    this.formInstance.template.classList.toggle('is-scroll', isScroll)
  }

  modalScrollHandler = (evt) => {
    this.setShadowForFooter(evt.target)
  }

  formSuccessHandler = (evt) => {
    const { upSaleType, formData } = evt.sendingResult

    this.type === 'consult' && upSaleType === 'course'
      ? showAlchemerePopup('lead')
      : this.submitHandler(formData.get('nomenclature'))

    this.close()
  }
}

export const showUpSaleModal = (template, { submitHandler, type, blockName }) => {
  if (!template) {
    return
  }

  new UpSaleModal(template, { submitHandler, type, blockName }).init()
}
