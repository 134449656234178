import { UiLoaderAnimationVanilla } from '@skillbox/front-box/dist/Loaders/UiLoaderAnimation/UiLoaderAnimationVanilla'
import { getUiPictureTemplate } from '@skillbox/front-box/dist/Media/UiPicture/UiPictureTemplate'

const getVideoSrc = (data) => {
  return data.player_type === 'vk'
    ? `https://vk.com/video_ext.php?${data.video_id}&hd=2&origin=${window.location.origin}&js_api=1`
    : `https://www.youtube.com/embed/${data.video_id}`;
};

const getVideoTemplate = (data) => {
  switch (data.player_type) {
    case 'youtube':
    case 'vk':
      return (
        `<iframe
          class="speakers-v3-popup__media speakers-v3-popup__media--video"
          width="560"
          height="315"
          src="${getVideoSrc(data)}"
          title="Пример лекции спикера"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>`
      );
    case 'default':
      return (
        `<video
          class="speakers-v3-popup__media speakers-v3-popup__media--video"
          width="560"
          height="315"
          poster="${data.poster}"
          controlsList="nodownload"
          controls
          autoplay
          playsinline
          title="Пример лекции спикера"
        >
          ${data.src.webm ? `<source src="${data.src.webm}" type="video/webm">`  : ''}
          <source src="${data.src.mp4}" type="video/mp4">
        </video>`
      )
  }
};

export const getLoaderTemplate = () => UiLoaderAnimationVanilla({ classes: 'speakers-v3-popup__loader' });

export const getContentTemplate = ({ speaker, groups }) => {
  let index = 0;

  return (
    `<div class="speakers-v3-popup__groups">
      <div class="speakers-v3-popup__author">
        ${getUiPictureTemplate({
          imagesData: speaker.avatar,
          className: 'speakers-v3-popup__picture',
          width: 180,
          height: 210,
          alt: `Фотография ${speaker.name}`
        })}

        <h2 class="speakers-v3-popup__title h h--4">
          ${speaker.name}
        </h2>

        ${speaker.position
          ? `<span class="speakers-v3-popup__experience t t--3 ui-content-wrapper">${speaker.position}</span>`
          : ''
        }

        ${speaker.description
          ? `<div class="speakers-v3-popup__desc p p--2 ui-content-wrapper">${speaker.description}</div>`
          : ''
        }
      </div>

      ${groups?.map(({ title, previews }) => {
        return (
          `<div class="speakers-v3-popup__examples">
            ${title
              ? `<h3 class="speakers-v3-popup__subtitle h h--6">
                  ${title}
                </h3>`
              : ''
            }

            <ul class="speakers-v3-popup__list">
              ${previews.map(({ picture,  description, video }) => {
                if (!picture && !video) {
                  return '';
                }

                return (
                  `<li class="speakers-v3-popup__item">
                    ${video
                      ? `<div class="speakers-v3-popup__media-wrapper">
                          ${getVideoTemplate(video)}
                        </div>`
                      : `<button
                          class="speakers-v3-popup__details"
                          type="button"
                          data-details="button"
                          data-index="${++index}"
                        >
                          ${getUiPictureTemplate({
                            imagesData: picture,
                            className: 'speakers-v3-popup__media-wrapper speakers-v3-popup__media-wrapper--image',
                          })}
                        </button>`
                    }

                    ${description
                      ?  `<span class="speakers-v3-popup__pic-desc p p--3">
                            ${description}
                          </span>`
                      : ''
                    }
                  </li>`
                )
              }).join('')}
            </ul>
          </div>`
        )
      }).join('')}
    </div>`
  )
};

export const getSpeakerTemplate = () => {
  return (
    `<div class="speakers-v3-popup__wrapper">
        <div class="speakers-v3-popup__content"></div>
    </div>`
  )
};
