import UiModal from '../../../../js/components/UiModal/UiModal'
import Form from '../../../../js/Form/Form'
import { defaultRules } from '../../../../js/Form/default-rules'
import { createElement, render, RenderPosition } from '../../../../js/utils/render'

const getHeaderTemplate = ({ title, description }) => (
  `<header class="trap-modal__header ui-typography-group">
    <h3 class="trap-modal__title h h--4">
      ${title}
    </h3>

    ${description
      ? `<p class="trap-modal__desc t t--2">
          ${description}
        </p>`
      : ''
    }
  </header>`
)

class TrapModal extends UiModal {
  /**
   * @param {HTMLElement} template
   * @param {Object} options
   * @param {Object} options.header
   * @param {String} options.header.title
   * @param {String} options.header.description
   * @param {Array} options.hiddenFields
   * @param {Function} options.submitHandler
   * @param {Function} options.closeHandler
   */
  constructor(template, options = {}) {
    super(template, {
      wrapperClass: 'trap-modal',
      outerScroll: true,
      isIndent: false
    })

    this.form = null
    this.content = template

    this.header = options.header ?? JSON.parse(template.dataset.header)
    this.hiddenFields = this.getHiddenFields(JSON.parse(template.dataset.hiddenFields), options.hiddenFields)
    this.submitHandler = options.submitHandler
    this.closeHandler = options.closeHandler
  }

  beforeMounted() {
    this.form = this.element.querySelector('.form')

    this.renderHeaderElement(this.header)
    this.formInit()
  }

  beforeUnmounted() {
    this.form.removeEventListener('form-sended', this.submitFormHandler);
  }

  beforeClose() {
    this.closeHandler?.()
  }

  formInit() {
    this.renderHiddenFields(this.form, this.hiddenFields)
    this.form.addEventListener('form-sended', this.submitFormHandler)
    this.form.addEventListener('form-alchemere', this.close)

    new Form({
      form: this.form,
      rules: { phone: defaultRules.phone },
      blockName: 'Trap Modal'
    }).init()
  }

  submitFormHandler = (evt) => {
    this.submitHandler?.(evt)
  }

  getHiddenFields(mainFields, newFields) {
    if (!newFields) {
      return mainFields
    }

    const fieldsMap = [...mainFields, ...newFields].reduce((acc, item) => {
      acc[item.name] = item.value
      return acc
    }, {})

    return Object.keys(fieldsMap).map(name => ({ name, value: fieldsMap[name] }))
  }

  renderHiddenFields(container, hiddenFields) {
    const fragment = document.createDocumentFragment()

    hiddenFields.forEach(({ name, value }) => {
      const hiddenField = createElement(`<input name="${name}" type="hidden" value="${value}">`)
      render(fragment, hiddenField)
    })

    render(container, fragment)
  }

  renderHeaderElement(headerData) {
    render(this.content, createElement(getHeaderTemplate(headerData)), RenderPosition.AFTERBEGIN)
  }
}

export const showTrapModal = (options) => {
  const template = document.querySelector('#trap-modal')?.content
    .cloneNode(true).querySelector('.trap-modal__wrapper');

  if (!template) {
    const err = new Error(`[Trap Modal]: шаблон с id=trap-modal отсутствует`)
    console.error(err)
    window.Sentry?.captureException(err)
    return
  }

  const trapModal = new TrapModal(template, options)
  trapModal.init()

  return trapModal
}
