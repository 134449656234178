import { getCookie } from './cookies'

function getUrlParameter(sParam, string) {
  const sPageURL = string.substring(1)
  const sURLVariables = sPageURL.split('&')
  let sParameterName
  let i

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=')

    if (sParameterName[0] === sParam) {
      return typeof sParameterName[1] === 'undefined' ? true : decodeURIComponent(sParameterName[1])
    }
  }
  return false
}

function currentDateYYYYMMDD() {
  const d = new Date()
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) {
    month = '0' + month
  }
  if (day.length < 2) {
    day = '0' + day
  }
  return [year, month, day].join('-')
}

export function getExtraParams() {
  // Код из FastTrack
  // передали гет-параметры
  let extraParams = ''

  if (getUrlParameter('utm_medium', window.location.search)) {
    // предполагаем, что все остальные UTM тоже есть в гет-параметрах, и просто ничего не делаем: гет-параметры уже добавлены в ссылки
  } else if (getCookie('utm_aggregated_data') !== undefined) {
    let utmAggregatedData = getCookie('utm_aggregated_data')

    if (utmAggregatedData) {
      utmAggregatedData = utmAggregatedData.replace(/\|\|\|/g,'&')
      extraParams += '&' + utmAggregatedData
    }
  } else {
    extraParams += '&utm_source=fasttrack&utm_medium=messages&utm_campaign=all_managers_fasttrack_messages_automation_all_all_skillbox&utm_term=automation&utm_content=' + currentDateYYYYMMDD()
  }

  // добавляем куку advcake
  const advcakeCookie = getCookie('advcake_trackid')
  if (advcakeCookie) {
    extraParams += '&advcake_cookie=' + advcakeCookie
  }

  // передали URL страницы, на которой мы находимся
  const url = document.location.origin + document.location.pathname
  extraParams = extraParams + '&url=' + url

  return extraParams.substring(1)
}
