export const UpdateType = {
  INIT: 'INIT',
  AUTH: 'AUTH',
  CONNECT: 'CONNECT',
  CLICK: 'CLICK',
  ERROR: 'ERROR'
};

export const Media = {
  SM_MAX: 767.9,
  MD: 768,
  MD_MAX: 1023.9,
  LG: 1024,
  LG_MAX: 1279.9,
  XL: 1280
};

export const VK_STORAGE_KEY = 'user-was-auth-in-vk';

export const SubmitType = {
  CONSULT: 'consult',
  PAY: 'pay'
}
